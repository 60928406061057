import { Box, Button, CircularProgress, Dialog, DialogTitle, IconButton, Tooltip, Typography } from "@mui/material";
import React, { useState } from "react";
import { downloadExcel } from "../utils/Utils";
import deleteRecord from '../assets/delete.png'
import { useDispatch } from "react-redux";
import { uiActions } from "../store/reducers/ui-slice";
import ButtonContainer from "./buttonContainer";

const IsDeleteModal = (props) => {
    const { onPress } = props
    const [open, setOpen] = useState(false)
    return (
        <>
            <Tooltip title={'Delete'}>
                <IconButton sx={{ width: '40px', margin: '0px auto' }} onClick={() => setOpen(true)}>
                    <img src={deleteRecord} style={{ width: '25px', height: '25px', objectFit: 'contain' }} />
                </IconButton>
            </Tooltip>
            <Dialog onClose={() => setOpen(false)} open={open}>
                <DialogTitle>Are you sure you want to delete?</DialogTitle>
                <Box sx={{ display: 'flex', justifyContent: 'center', padding: '10px' }}>
                    <ButtonContainer>
                        <Button size="medium" variant="contained" onClick={() => setOpen(false)} >No</Button>
                        <Button size="medium" variant="contained" color="error" onClick={onPress}>Yes</Button>
                    </ButtonContainer>
                </Box>
            </Dialog>
        </>
    );
};

export default IsDeleteModal;
