import { Table, TableHead, TableRow, TableCell, TableBody, Box, Typography, CircularProgress } from '@mui/material';
import { useTheme } from '@emotion/react';
import './table.scss'
import TablePaginationComp from './tablePagination';



const TableContainer = (props) => {
  const { tableHeader, action: Action, hasActionPermission, extraColumnFeilds, extraColumn: ExtraColumn, extraColumnParams, addtionalRow: AddRow, data, verifyParam, isLoading, actionText, count, fetchList } = props;
  const theme = useTheme()
  console.log(hasActionPermission)
  return (
    <>
      <Box sx={{ flex: 1, overflowX: 'auto' }}>
        <Box className='mainTableContainer'>
          <Table size="small">
            <TableHead className='tableHeader'>
              <TableRow >
                {
                  tableHeader.map((e, i) => (
                    <TableCell key={i} align={tableHeader.length == 1 ? 'left' : 'left'} className='tableCell'>{e}</TableCell>
                  ))
                }
                {
                  extraColumnFeilds && extraColumnFeilds.map((e, i) => (
                    <TableCell align='left' className='tableCell'>{e}</TableCell>
                  ))
                }
                {
                  Action && <TableCell className='tableCell' sx={{ maxWidth: '100px' }}>
                    {actionText ? actionText : "Detail"}
                  </TableCell>
                }
              </TableRow>
            </TableHead>

            {verifyParam && <TableBody className='tableBody'>
              {AddRow && <AddRow />}
              {
                data?.map((item, index) => (
                  <TableRow key={index} sx={{ background: index % 2 ? theme.palette.rowBackground : 'transparent', height: '20px !important' }}>
                    {/* <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 }, background: index % 2 ? theme.palette.rowBackground : 'transparent' }}> */}
                    {
                      verifyParam?.map((v, i) => (
                        <TableCell key={i} sx={{ height: '20px !important' }} align={tableHeader.length == 1 ? 'left' : 'left'}>
                          <Typography variant='body1'>{item[v]}</Typography>
                        </TableCell>
                      ))
                    }
                    {
                      extraColumnParams &&
                      ExtraColumn.map((Component, i) => (<TableCell align="left" sx={{ whiteSpace: 'nowrap' }}>
                        {Component({ id: item, index })}
                      </TableCell>
                      ))
                    }
                    {
                      Action && <TableCell sx={{ whiteSpace: 'nowrap', maxWidth: '100px' }}>
                        <Action id={item} index={index} />
                      </TableCell>
                    }
                  </TableRow>
                ))
              }
            </TableBody>}

          </Table>
          {
            isLoading &&
            <Box className='tableLoadingContainer'>
              <CircularProgress size={50} sx={{ margin: '20px' }} />
            </Box>
          }
        </Box>

      </Box>
      {count > 0 &&
        <Box sx={{}}>
          <TablePaginationComp count={count} fetchList={fetchList} />
        </Box>
      }
    </>
  );
}

export default TableContainer;