import React, { useEffect, useState } from "react";
import { Tooltip, Box, Typography, IconButton, Button } from '@mui/material';
import Card from "../../components/card/Card";
import ActionButton from "../../components/actionButton";
import TableContainer from "../../components/table/table";
import { useNavigate } from "react-router-dom";
import { getAllCompany } from "../../store/actions/company";
import { useDispatch, useSelector } from "react-redux";
import PermissionComp from "../../components/permissionComp/permissionComp";
import { getAllRFTSByPharmacy } from "../../store/actions/rfts";
import moment from "moment";
import details from '../../assets/details.png'
import edit from '../../assets/edit.png'
import deleteIcon from '../../assets/delete.png'
import ModalButton from "../../components/modalButton/modalButton";
import RftsDetailView from "./rftsDetailView";

const RFTSList = () => {
    const dispatch = useDispatch();
    const navigation = useNavigate()

    const [requests, setRequests] = useState([])
    const [isLoading, setIsLoading] = useState(false);
    const { user } = useSelector(state => state.auth)

    const tableHeader = ['Wearhouse Name', 'Request Date', 'Requested Time', 'Status']
    const verifyParam = ['wearHouseName', 'requestDate', 'requestTime', 'statusName']

    useEffect(() => {
        setIsLoading(true)
        let data = {
            "sid": null,
            "lid": null,
            "reqInfo": null,
            "msgType": "GETALLRFTSBYWEARHOUSEANDPHARMACY",
            "data": { "Pharmacy_ID": user?.locationID },
            "debug": null,
            "token": null
        }
        dispatch(getAllRFTSByPharmacy(data)).then(res => {
            setIsLoading(false)
            addLocalRecords(res.payload.data.reverse().map(ele => ({
                ...ele,
                requestDate: (moment(ele.rftsDate).format("YYYY-MM-DD")),
                requestTime: (moment(ele.rftsDate).format("h:mm a"))
            })))
        })
    }, [])

    const addLocalRecords = (list) => {
        let arr = [...list]
        getRFMKeysFromLocalStorage().map(e => {
            arr.push({
                wearHouseName: e.key.replace("RFM", '').split('_')[0],
                requestDate: e.key.replace("RFM", '').split('_')[1],
                statusName: 'Not Sync'
            })
        })
        setRequests(arr)
    }
    const getRFMKeysFromLocalStorage = () => {
        const rfmItems = [];

        for (let i = 0; i < localStorage.length; i++) {
            const key = localStorage.key(i);
            if (key.includes('RFM')) {
                const value = localStorage.getItem(key);
                rfmItems.push({ key, value });
            }
        }
        return rfmItems;
    };

    // useEffect(() => {

    //     let arr = []
    //     company.forEach((ele) => {
    //         const obj = { ...ele }
    //         obj['isActive'] = obj.isActive ? "Active" : obj.isActive ? "Non-Active" : '';
    //         arr.push(obj)
    //     })
    //     setCompanies(arr)

    // }, [company])

    const removeLocal = (data) => {
        let arr = [...requests]
        const index = arr.findIndex(val => val.requestDate == moment().format('YYYY-MM-DD') && val.statusName == 'Not Sync')
        if (index != -1) {
            arr.splice(index, 1);
            localStorage.removeItem(`RFM${data.wearHouseName}_${data.requestDate}`)
            setRequests(arr)
        }
    }

    return (
        <>
            <Box sx={{ margin: '15px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant='h1' >Request For Medication</Typography>
                <PermissionComp list={['RFM', 'create']}>
                    <ActionButton label={'Create Request'} onClick={() => navigation('create')} />
                </PermissionComp>
            </Box>
            <Card style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                <TableContainer
                    tableHeader={tableHeader}
                    data={requests}
                    verifyParam={verifyParam}
                    isLoading={isLoading}
                    action={(data) => <TableAction data={data} removeLocal={removeLocal} />} />
            </Card>
        </>
    );
};

export default RFTSList;

const TableAction = ({ data, removeLocal }) => {
    const { id } = data
    const [openEdit, setOpenEdit] = React.useState(false);
    const navigation = useNavigate()
    return (
        <>
            {id.statusName == 'Not Sync' ?
                <>
                    <Tooltip title="Edit">
                        <IconButton onClick={() => navigation('create', { state: id })} sx={{ width: '40px', margin: '0px auto' }}>
                            <img src={edit} style={{ width: '25px', height: '25px', objectFit: 'contain' }} />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete">
                        <IconButton onClick={() => removeLocal(id)} sx={{ width: '40px', margin: '0px auto' }}>
                            <img src={deleteIcon} style={{ width: '25px', height: '25px', objectFit: 'contain' }} />
                        </IconButton>
                    </Tooltip>
                </>
                : <ModalButton open={openEdit} setOpen={setOpenEdit} tooltip={'Edit Vendor'} img={details} type={'icon'}>
                    <RftsDetailView data={id} setOpen={setOpenEdit} />
                </ModalButton>
            }
        </>
    )
}
