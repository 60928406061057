import { Box, Button, Grid, IconButton, Typography } from "@mui/material";
import React, { useState } from "react";
import Card from "../../components/card/Card";
import './login.scss'
import { useForm } from "react-hook-form";
import InputFields from "../../components/InputFields";
import ActionButton from "../../components/actionButton";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../store/actions/auth";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
const Login = () => {
    const dispatch = useDispatch();
    const [showPassword, setShowPassword] = React.useState(false);
    const [isLoading, setIsLoading] = useState(false)

    const defaultValues = useForm({
        defaultValues: {
            UserName: "",
            Password: "",
        },
    });

    const {
        control,
        handleSubmit,
        setError,
        clearErrors,
        watch,
        reset,
        resetField,
        getValues,
        formState: { errors },
    } = defaultValues;

    const submit = () => {
        setIsLoading(true)
        let data = {
            "sid": null,
            "lid": null,
            "reqInfo": null,
            "msgType": "LOGIN",
            "data": getValues(),
            "debug": null,
            "token": null
        }
        dispatch(login(data)).then(res=>{
            setIsLoading(false)
        })
    }
    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            handleSubmit(submit)();  // Trigger form submission
        }
    };
    return (
        <Box className='loginContainer'>
            <Grid container spacing={2}>
                <Grid item xs={8} >
                </Grid>
                <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                    <Card style={{ height: '80%', width: '80%', display: 'flex', flexDirection: 'column', justifyContent: 'center', borderRadius: '10px', background: '#3b0082' }}>
                        <Box className='loginTitleContainer'>
                            <Typography variant='h2' color={'#fff'} sx={{ textAlign: 'center', margin: '20px 0px' }} > Inventory Management System</Typography>
                            {/* <Typography variant='h2' color={'#fff'} sx={{ margin: '20px 0px' }}>Welcome to IMS</Typography> */}
                            <Typography variant='body1' color={'#fff'} >Please Sign in with you account</Typography>
                        </Box>
                        <Box sx={{ maxWidth: '80%', margin: '0px auto' }}>
                            <form onKeyDown={handleKeyDown}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} >
                                        <InputFields
                                            fieldName="UserName"
                                            type="text"
                                            label="User Name"
                                            control={control}
                                            rules={{
                                                required: "UserName is required",
                                            }}
                                            error={errors?.UserName}
                                            color
                                        />
                                    </Grid>
                                    <Grid item xs={12} >
                                        <InputFields
                                            fieldName="Password"
                                            type={showPassword ? "text" : "password"}
                                            label="Password"
                                            control={control}
                                            rules={{
                                                required: "Password is required",
                                            }}
                                            error={errors?.Password}
                                            color
                                            inputProps={{
                                                endAdornment: (
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={() => setShowPassword(!showPassword)}
                                                    >
                                                        {showPassword ? <AiFillEyeInvisible /> : <AiFillEye />}
                                                    </IconButton>
                                                ),
                                            }}
                                        />
                                    </Grid>

                                </Grid>
                                <Box sx={{ display: 'flex', justifyContent: 'end', margin: '10px 0px' }}>
                                    <Button size="medium" variant="text" color='primary' >Forgot Password?</Button>
                                </Box>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} >
                                        <ActionButton style={{ width: '100%' }} label={'Login'} isLoading={isLoading} onClick={handleSubmit(submit)} />
                                    </Grid>
                                </Grid>
                            </form>
                        </Box>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    );
};

export default Login;
