import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import Card from "../../components/card/Card";
import ButtonContainer from "../../components/buttonContainer";
import InputFields from "../../components/InputFields";
import SelectFields from "../../components/SelectFields";
import product from '../../assets/product.png'
import './product.scss'
import ActionButton from "../../components/actionButton";
import { ProfilePhoto } from "../../components/profilePhoto/profilePhoto";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { createProduct, updateProduct } from "../../store/actions/product";
import { setDataObject } from "../../utils/Utils";
import { createProductRecord, updateRecord } from "../../store/reducers/product-slice";
import SaveButton from "../../components/saveButton/saveButton";

const ProductForm = (props) => {
    const { data, setOpen } = props
    const dispatch = useDispatch();
    const { companyList, vendorList, medicationTypeList } = useSelector(state => state.filler)
    const { user } = useSelector(state => state.auth)

    const [isLoading, setIsLoading] = useState(false)

    const defaultValues = useForm({
        defaultValues: {
            ProductName: "",
            ProductGenericName: "",
            Mg: '',
            ProductCompany: "",
            CompanyID: "",
            DinMaster: "",
            UpcMaster: "",
            DrgIdMaster: "",
            DrgPackIdMaster: "",
            QuickCodeMaster: "",
            BarCode: "",
            QRCode: "",
            Vendor_ID: "",
            Dosage_ID: "",
        },
    });

    const {
        control,
        handleSubmit,
        setError,
        clearErrors,
        watch,
        reset,
        resetField,
        getValues,
        formState: { errors },
    } = defaultValues;

    useEffect(() => {
        console.log(data)
        if (data?.id) {
            reset({
                ProductName: data.productName || "",
                ProductGenericName: data.productGenericName || "",
                Mg: data.mg || "",
                ProductCompany: data.productCompany || "",
                CompanyID: data.company_ID || "",
                DinMaster: data.diN_Master || "",
                UpcMaster: data.upC_Master || "",
                DrgIdMaster: data.drgID_Master || "",
                DrgPackIdMaster: data.drgPackID_Master || "",
                QuickCodeMaster: data.quickCode_Master || "",
                BarCode: data.barCode || "",
                QRCode: data.qrCode || "",
                Vendor_ID: data.vendor_ID || "",
                Dosage_ID: data.dosage_ID || "",
            });
        }
    }, []);


    const submit = () => {
        setIsLoading(true)
        let saveObj = {
            "sid": null,
            "lid": null,
            "reqInfo": null,
            "msgType": "INSERTPRODUCT",
            "data": setDataObject({ ...getValues(), "CreatedBy": user?.userId }),
            "debug": null,
            "token": null
        }

        if (user?.userType != 1) {
            saveObj['data']['CompanyID'] = user?.businessId
        }

        if (data?.id) {
            let updateObj = { ...saveObj }
            updateObj['data'] = { ...saveObj['data'], 'ID': data.id, 'EditBy': user?.userId, ISUPDATE: 1 }
            updateObj['msgType'] = 'UPDATEPRODUCT'
            const sentObj = updateObj.data
            const saveObject = {
                id: sentObj.ID,
                productName: sentObj.ProductName,
                productGenericName: sentObj.ProductGenericName,
                mg: sentObj.Mg,
                productCompany: sentObj.ProductCompany,
                company_ID: sentObj.CompanyID,
                createdOn: data.createdOn,
                createdBy: sentObj.CreatedBy,
                editOn: data.editOn,
                editBy: sentObj.EditBy,
                isActive: data.isActive,
                diN_Master: sentObj.DinMaster,
                upC_Master: sentObj.UpcMaster,
                drgID_Master: sentObj.DrgIdMaster,
                drgPackID_Master: sentObj.DrgPackIdMaster,
                quickCode_Master: sentObj.QuickCodeMaster,
                packSize_Master: data.packSize_Master,
                companyName: data.companyName,
                vendor_ID: sentObj.Vendor_ID,
                dosage_ID: sentObj.Dosage_ID,
                recordCount: data.recordCount,
                rowNo: data.rowNo,
                pageIndex: data.pageIndex,
                pageSize: data.pageSize,
                barCode: sentObj.BarCode,
                qrCode: sentObj.QRCode,
                vendorValue: data.vendorValue
            }
            dispatch(updateProduct(updateObj)).then(res => {
                setIsLoading(false)
                dispatch(updateRecord(saveObject))
                setOpen(false)
            })
        } else {
            dispatch(createProduct(saveObj)).then(res => {
                setIsLoading(false)
                let obj = res.payload
                obj['company_ID'] = obj.companyId
                obj['diN_Master'] = obj.dinMaster
                obj['drgID_Master'] = obj.drgIdMaster
                obj['drgPackID_Master'] = obj.drgPackIdMaster
                obj['quickCode_Master'] = obj.quickCodeMaster
                obj['upC_Master'] = obj.upcMaster
                delete obj.inventoryTb
                delete obj.poDetailTb
                delete obj.productDetailTb
                delete obj.rftsdetailTb
                delete obj.stoDetailTb
                delete obj.companyId
                delete obj.dinMaster
                delete obj.drgIdMaster
                delete obj.drgPackIdMaster
                delete obj.quickCodeMaster
                delete obj.upcMaster
                dispatch(createProductRecord(obj))
                setOpen(false)
            })
        }
    }


    return <Card style={{ flex: 1, }}>
        <Box className="productFormContainer">
            {/* <Typography variant='h2' >General Information</Typography>
            <Divider sx={{ margin: '10px 0px' }} /> */}
            <form>
                <Typography variant='h3' >Profile picture upload</Typography>
                <Box className='productProfileContainer'>
                    <ProfilePhoto image={product} />
                    <ActionButton label={'Upload product Photo'} />
                </Box>
                <Grid container spacing={1}>
                    <Grid item xs={12} >
                        <Typography variant='h3' >Product Information</Typography>
                    </Grid>
                    <Grid item xs={4} >
                        <InputFields
                            fieldName="ProductName"
                            type="text"
                            label="Product Name"
                            control={control}
                            rules={{
                                required: "Product Name is required",
                            }}
                            error={errors?.ProductName}
                        />
                    </Grid>
                    <Grid item xs={4} >
                        <InputFields
                            fieldName="Mg"
                            type="text"
                            label="Strenght"
                            control={control}
                            rules={{
                                required: "Strenght is required",
                            }}
                            error={errors?.Mg}
                        />
                    </Grid>
                    {user?.userType == 1 && <Grid item xs={4} >
                        <SelectFields
                            fieldName="CompanyID"
                            label="Company"
                            control={control}
                            options={companyList.map(({ name, id }) => {
                                return { label: name, value: id };
                            })}

                            rules={{
                                required: "Company is required",
                            }}
                            error={errors?.CompanyID}
                        />
                    </Grid>}
                    <Grid item xs={4} >
                        <SelectFields
                            fieldName="Vendor_ID"
                            label="Vendor"
                            control={control}
                            options={vendorList.map(({ name, id }) => {
                                return { label: name, value: id };
                            })}

                            rules={{
                                required: "Vendor is required",
                            }}
                            error={errors?.Vendor_ID}
                        />
                    </Grid>
                    <Grid item xs={4} >
                        <SelectFields
                            fieldName="Dosage_ID"
                            label="Dosage"
                            control={control}
                            options={medicationTypeList.map(({ name, id }) => {
                                return { label: name, value: id };
                            })}

                        />
                    </Grid>
                    <Grid item xs={4} >
                        <InputFields
                            fieldName="DinMaster"
                            type="text"
                            label="Din Master"
                            control={control}
                            rules={{
                                required: "Din Master is required",
                            }}
                            error={errors?.DinMaster}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <ButtonContainer isSingle>
                            <SaveButton isLoading={isLoading} onClick={handleSubmit(submit)} title={'Save'} />
                        </ButtonContainer>
                    </Grid>
                </Grid>
            </form>
        </Box>
    </Card>
};

export default ProductForm;
