import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Box, Button, Divider, Grid, IconButton, Typography } from "@mui/material";
import Card from "../../components/card/Card";
import ButtonContainer from "../../components/buttonContainer";
import InputFields from "../../components/InputFields";
import SelectFields from "../../components/SelectFields";
import userProfile from '../../assets/userProfile.png'
import './users.scss'
import ActionButton from "../../components/actionButton";
import { ProfilePhoto } from "../../components/profilePhoto/profilePhoto";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { userRole } from "../../utils/lists";
import { createUser, updateUser } from "../../store/actions/user";
import { getCities, getStates } from "../../store/actions/address";
import { setDataObject } from "../../utils/Utils";
import SaveButton from "../../components/saveButton/saveButton";
import { createRecord, updateRecord } from "../../store/reducers/user-slice";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";

const UsersForm = (props) => {
    const { data, setOpen } = props
    const dispatch = useDispatch();
    const { companyList, wearhouseList, pharmacyList } = useSelector(state => state.filler)
    const { user } = useSelector(state => state.auth)
    const [isLoading, setIsLoading] = useState(false)

    const { countries } = useSelector(state => state.address)

    const [showPassword, setShowPassword] = React.useState(false);

    const [state, setState] = useState([])
    const [city, setCity] = useState([])

    const defaultValues = useForm({
        defaultValues: {
            FirstName: "",
            MiddleName: "",
            LastName: "",
            UserName: "",
            Password: "",
            Email: "",
            Phone: "",
            CompanyID: "",
            UserProfileId: "",
            Address: "",
            CityId: "",
            CountryId: "",
            StateId: "",
            LocationID: ''
        },
    });

    const {
        control,
        handleSubmit,
        setError,
        clearErrors,
        watch,
        reset,
        resetField,
        setValue,
        getValues,
        formState: { errors },
    } = defaultValues;

    useEffect(() => {
        if (watch('CountryId') != "") {
            resetField("StateId", { defaultValue: '' });
            resetField("CityId", { defaultValue: '' });
            dispatch(getStates({ country_code: watch('CountryId') })).then((res) => {
                setState(res.payload)
                const newStateId = res.payload.find(val => val.id === data?.stateID)?.iso2;
                if (newStateId) {
                    resetField("StateId", { defaultValue: newStateId });
                }
            })
        }
    }, [watch('CountryId'), data?.stateID])

    useEffect(() => {
        if (watch('StateId') != "") {
            resetField("CityId", { defaultValue: '' });
            dispatch(getCities({ country_code: watch('CountryId'), state_code: watch('StateId') })).then((res) => {
                setCity(res.payload)
                if (data?.cityID) {
                    resetField("CityId", { defaultValue: data?.cityID });
                }
            })
        }
    }, [watch('StateId')])


    useEffect(() => {
        resetField("LoacationID", { defaultValue: '' });
    }, [watch('UserProfileId')])


    useEffect(() => {
        const country = countries.find(val => val?.id == data?.countryID)?.iso2
        if (data?.id) {
            reset({
                FirstName: data.firstName || "",
                MiddleName: data.middleName || "",
                LastName: data.lastName || "",
                UserName: data.userName || "",
                Password: data.password || "",
                Email: data.email || "",
                Phone: data.phone || "",
                CompanyID: data.companyID || "",
                UserProfileId: data.userProfile_ID || "",
                Address: data.userAddress || "",
                CountryId: country || "",
                LocationID: data.locationID || ""
            });
        }
    }, [data, countries])

    const submit = () => {
        setIsLoading(true)
        const obj = {
            ...getValues()
        }
        const country = watch('CountryId') && countries.find(val => val?.iso2 == watch('CountryId'))?.id
        const stateId = watch('StateId') && state.find(val => val.iso2 == watch('StateId'))?.id
        let saveObj = {
            "sid": null,
            "lid": null,
            "reqInfo": null,
            "msgType": "INSERTUSER",
            "data": setDataObject({ ...obj, 'CountryId': country, 'StateId': stateId, "CreatedBy": user?.userId }),
            "debug": null,
            "token": null
        }

        if (user?.userType != 1) {
            saveObj['data']['CompanyID'] = user?.businessId
        }
        if (data?.id) {
            let updateObj = { ...saveObj }
            updateObj['data'] = { ...saveObj['data'], 'ID': data.id, 'EditBy': user?.userId, ISUPDATE: 1 }
            updateObj['msgType'] = 'UPDATEUSER'
            const sentObj = updateObj.data
            const saveObject = {
                id: sentObj.ID,
                userName: sentObj.UserName,
                password: sentObj.Password,
                email: sentObj.Email,
                phone: sentObj.Phone,
                createdOn: data.createdOn,
                createdBy: sentObj.CreatedBy,
                isActive: data.isActive,
                companyID: sentObj.CompanyID,
                userProfile_ID: sentObj.UserProfileId,
                firstName: sentObj.FirstName,
                middleName: sentObj.MiddleName,
                lastName: sentObj.LastName,
                userProfileName: data.userProfileName,
                createdByName: data.createdByName,
                companyName: data.companyName,
                locationID: sentObj.LocationID,
                userAddress: sentObj.Address,
                cityID: sentObj.CityId,
                stateID: sentObj.StateId,
                countryID: sentObj.CountryId,
                Role: data.Role
            }
            dispatch(updateUser(updateObj)).then(res => {
                setIsLoading(false)
                dispatch(updateRecord(saveObject))
                setOpen(false)
            })
        } else {
            dispatch(createUser(saveObj)).then(res => {
                let obj = res.payload
                dispatch(createRecord(obj))
                setIsLoading(false)
                setOpen(false)
            })
        }
    }




    return <Card style={{ flex: 1, }}>
        <Box className="usersFormContainer">
            <form>
                <Typography variant='h3' >Profile picture upload</Typography>
                <Box className='usersProfileContainer'>
                    <ProfilePhoto image={userProfile} />
                    <ActionButton label={'Upload Users Photo'} />
                </Box>
                <Grid container spacing={1}>
                    <Grid item xs={12} >
                        <Typography variant='h3' >User Information</Typography>
                    </Grid>
                    <Grid item xs={4} >
                        <InputFields
                            fieldName="FirstName"
                            type="text"
                            label="First Name"
                            control={control}
                            rules={{
                                required: "First Name is required",
                            }}
                            error={errors?.FirstName}
                        />
                    </Grid>
                    <Grid item xs={4} >
                        <InputFields
                            fieldName="MiddleName"
                            type="text"
                            label="Middle Name"
                            control={control}
                        />
                    </Grid>
                    <Grid item xs={4} >
                        <InputFields
                            fieldName="LastName"
                            type="text"
                            label="Last Name"
                            control={control}
                            rules={{
                                required: "Last Name is required",
                            }}
                            error={errors?.LastName}
                        />
                    </Grid>
                    <Grid item xs={4} >
                        <InputFields
                            fieldName="UserName"
                            type="text"
                            label="User Name"
                            control={control}
                            rules={{
                                required: "User Name is required",
                            }}
                            error={errors?.UserName}
                        />
                    </Grid>
                    <Grid item xs={4} >
                        <InputFields
                            fieldName="Password"
                            type={showPassword ? "text" : "password"}
                            label="Password"
                            control={control}
                            rules={{
                                required: "Password is required",
                            }}
                            error={errors?.Password}
                            inputProps={{
                                endAdornment: (
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => setShowPassword(!showPassword)}
                                    >
                                        {showPassword ? <AiFillEyeInvisible /> : <AiFillEye />}
                                    </IconButton>
                                ),
                            }}
                        />
                    </Grid>
                    <Grid item xs={4} >
                        <InputFields
                            fieldName="Email"
                            type="text"
                            label="Email Address"
                            control={control}
                            rules={{
                                required: "Email Address is required",
                            }}
                            error={errors?.Email}
                        />
                    </Grid>
                    {user?.userType == 1 && watch('UserProfileId') != 1 && <Grid item xs={4} >
                        <SelectFields
                            fieldName="CompanyID"
                            label="Company"
                            control={control}
                            options={companyList.map(({ name, id }) => {
                                return { label: name, value: id };
                            })}

                            rules={{
                                required: "Company is required",
                            }}
                            error={errors?.CompanyID}
                        />
                    </Grid>}
                    <Grid item xs={4} >
                        <InputFields
                            fieldName="Phone"
                            type="text"
                            label="Phone"
                            control={control}
                            rules={{
                                required: "Phone is required",
                            }}
                            error={errors?.Phone}
                        />
                    </Grid>
                    <Grid item xs={4} >
                        <SelectFields
                            fieldName="UserProfileId"
                            label="User Profile"
                            control={control}
                            options={user.userType != 1 ? userRole.filter(val => val.id != 1).map(({ name, id }) => {
                                return { label: name, value: id };
                            }) :
                                userRole.map(({ name, id }) => {
                                    return { label: name, value: id };
                                })}

                            rules={{
                                required: "User Profile is required",
                            }}
                            error={errors?.UserProfileId}
                        />
                    </Grid>
                    {watch('UserProfileId') == 3 && <Grid item xs={4} >
                        <SelectFields
                            fieldName="LocationID"
                            label="Wearhouse"
                            control={control}
                            options={wearhouseList.map(({ name, id }) => {
                                return { label: name, value: id };
                            })}
                            rules={{
                                required: "Wearhouse is required",
                            }}
                            error={errors?.LocationID}
                        />
                    </Grid>}
                    {watch('UserProfileId') == 4 && <Grid item xs={4} >
                        <SelectFields
                            fieldName="LocationID"
                            label="Pharmacy"
                            control={control}
                            options={pharmacyList.map(({ name, id }) => {
                                return { label: name, value: id };
                            })}
                            rules={{
                                required: "Pharmacy is required",
                            }}
                            error={errors?.LocationID}
                        />
                    </Grid>}

                    <Grid item xs={12} >
                        <Typography variant='h3' >Address</Typography>
                    </Grid>

                    <Grid item xs={4} >
                        <InputFields
                            fieldName="Address"
                            type="text"
                            label="Address"
                            control={control}
                        />
                    </Grid>

                    <Grid item xs={4} >
                        <SelectFields
                            fieldName="CountryId"
                            label="Country"
                            control={control}
                            options={countries.map(({ name, iso2, }) => {
                                return { label: name, value: iso2 };
                            })}

                        />
                    </Grid>

                    <Grid item xs={4} >
                        <SelectFields
                            fieldName="StateId"
                            label="State"
                            control={control}
                            disabled={!watch('CountryId')}
                            options={state && state?.map(({ name, iso2 }) => {
                                return { label: name, value: iso2 };
                            })}

                        />
                    </Grid>

                    <Grid item xs={4} >
                        <SelectFields
                            fieldName="CityId"
                            label="City"
                            control={control}
                            disabled={!watch('StateId')}
                            options={city && city?.map(({ name, id }) => {
                                return { label: name, value: id };
                            })}

                        />
                    </Grid>

                    <Grid item xs={12}>
                        <ButtonContainer isSingle>
                            <SaveButton isLoading={isLoading} onClick={handleSubmit(submit)} title={'Save'} />
                        </ButtonContainer>
                    </Grid>
                </Grid>
            </form>
        </Box>
    </Card>
};

export default UsersForm;
