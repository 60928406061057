import { TextField } from "@mui/material";
import { styled } from '@mui/material/styles';
import React from "react";
import { Controller } from "react-hook-form";
import { onlyPhone } from "../utils/Utils";

const CssTextField = styled(TextField)(({ theme, inputColor }) => ({
  '& label.Mui-focused': {
    color: inputColor ? '#fff' : theme.palette.inputLabelFocusedColor,
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: inputColor ? '#fff' : theme.palette.inputFieldsetColor,
    },
    '&:hover fieldset': {
      borderColor: inputColor ? '#fff' : theme.palette.inputFocusedColor,
    },
    '&.Mui-focused fieldset': {
      borderColor: inputColor ? '#fff' : theme.palette.inputFocusedColor,
    },
  },
  '& .MuiOutlinedInput-input': {
    color: inputColor ? '#fff' : theme.palette.textColor
  },
  '& .MuiInputLabel-root': {
    color: inputColor ? '#fff' : theme.palette.textColor
  }
}));
const InputFields = ({ error, type, fieldName, control, rules, label, phone, disabled, maxlimit, inputProps, helperText, pattern, color, ...rest }) => {

  return (
    <Controller
      name={fieldName}
      control={control}
      rules={rules}
      render={({ field }) => {
        const handleChange = (event) => {
          const newValue = event.target.value;
          if (pattern.test(newValue)) {
            field.onChange(event);
          }
        };
        return (
          // <Tooltip title={rules?.required ? rules.required : ""}>
          <CssTextField
            inputColor={color}
            size="small"
            className="outlined"
            required={(rules?.required) && true}
            variant="outlined"
            error={error && true}
            helperText={error ? error.message : " "}
            type={type}
            inputRef={field.ref}
            label={label}
            value={phone ? onlyPhone(field.value) : field.value}
            onChange={(e) => pattern ? handleChange(e) : maxlimit ? e.target.value <= maxlimit && field.onChange(e) : field.onChange(e)}
            fullWidth
            disabled={disabled}
            autoComplete=""
            InputProps={inputProps}
            {...rest}
          />
          // </Tooltip>
        );
      }}
    />
  );
};

export default InputFields;
