import React, { useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import MainLayout from "./mainlayout/mainlayout";

const RequireAuth = () => {
  const ims = localStorage.getItem(('IMS'));
  const location = useLocation();

  if (!ims) {
    return (
      <Navigate
        to="/login"
        state={{
          visitedLocation: location.pathname,
        }}
        replace
      />
    );
  }
  return <MainLayout />;
};
export default RequireAuth;
