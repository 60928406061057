import { createSlice } from "@reduxjs/toolkit";
import { getAllUser } from "../actions/user";

const initialState = {
  user: [],
  totalcount: 0,
}

const userSlice = createSlice({
  name: "user",
  initialState,

  extraReducers: (builder) => {
    builder.addCase(getAllUser.pending, (state, action) => {
      state.user = []
    });
    builder.addCase(getAllUser.fulfilled, (state, action) => {
      state.user = action.payload.data
      // state.totalcount = action.payload.total
    });
  },
  reducers: {
    createRecord: (state, action) => {
      state.user.unshift(action.payload)
    },
    updateRecord: (state, action) => {
      const getIndex = state.user.findIndex(item => item.id == action.payload.id)
      state.user[getIndex] = action.payload;
    },
    deleteRecord: (state, action) => {
      const getIndex = state.user.findIndex(item => item.id == action.payload.ID)
      state.user.splice(getIndex, 1)
    },
  },
});
export default userSlice.reducer;
export const { createRecord, updateRecord, deleteRecord } = userSlice.actions;
