import React, { useEffect, useState } from "react";
import { Tooltip, Box, Typography, IconButton, Button, Grid } from '@mui/material';
import Card from "../../components/card/Card";
import ActionButton from "../../components/actionButton";
import TableContainer from "../../components/table/table";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllProduct, updateProduct } from "../../store/actions/product";
import details from '../../assets/details.png'
import edit from '../../assets/edit.png'
import SearchBar from "../../components/searchBar/searchBar";
import PermissionComp from "../../components/permissionComp/permissionComp";
import { filterName } from "../../utils/Utils";
import ModalButton from "../../components/modalButton/modalButton";
import ProductForm from "./productForm";
import ProductDetail from "./productDetail";
import PackSizeForm from "./packSizeForm";
import ButtonContainer from "../../components/buttonContainer";
import PackSize from "./packSize";
import IsDeleteModal from "../../components/isDelete";
import { deleteRecord } from "../../store/reducers/product-slice";

const Product = () => {
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const navigation = useNavigate()
    const { product, totalcount } = useSelector(state => state.product)
    const { vendorList, medicationTypeList } = useSelector(state => state.filler)

    const { user } = useSelector(state => state.auth)
    const [Products, setProducts] = useState([])
    const [open, setOpen] = React.useState(false);
    const [openPackSize, setOpenPackSize] = React.useState(false);
    const [search, setSearch] = React.useState('');



    const tableHeader = ['DIN Number', 'Vendor', 'Product Name', 'Type of Medication', 'Strenght']
    const verifyParam = ['diN_Master', 'vendorValue', 'productName', 'dosageValue', 'mg']

    const fetchList = (obj, rowsPerPage, page) => {
        setIsLoading(true)
        let data = {
            "sid": null,
            "lid": null,
            "reqInfo": null,
            "msgType": "GETALLPRODUCT",
            "data": { "Company_ID": user?.businessId, 'PageIndex': page ? page + 1 : 1, "PageSize": rowsPerPage ? rowsPerPage : 10 },
            "debug": null,
            "token": null
        }
        dispatch(getAllProduct(data)).then(res => {
            setIsLoading(false)
        })
    }

    useEffect(() => {
        let arr = []
        product.forEach((ele) => {
            const obj = { ...ele }
            obj['isActive'] = obj.isActive ? "Active" : obj.isActive ? "Non-Active" : '';
            obj['vendorValue'] = filterName(vendorList, obj?.vendor_ID);
            obj['dosageValue'] = filterName(medicationTypeList, obj?.dosage_ID);
            arr.push(obj)
        })
        setProducts(arr)

    }, [product])

    useEffect(() => {
        if (search) {
            handleSearch()
        } else {
            fetchList()
        }
    }, [search])

    const handleSearch = (obj, rowsPerPage, page) => {
        setIsLoading(true)
        let data = {
            "sid": null,
            "lid": null,
            "reqInfo": null,
            "msgType": "GETALLPRODUCTBYFILTER",
            "data": { "Company_ID": user?.businessId, "Filter": search, 'PageIndex': page ? page + 1 : 1, "PageSize": rowsPerPage ? rowsPerPage : 10, },
            "debug": null,
            "token": null
        }
        dispatch(getAllProduct(data)).then(res => {
            setIsLoading(false)
        })
    }

    const deleteProduct = (data) => {
        let obj = {
            "ProductName": null,
            "ProductGenericName": null,
            "Mg": null,
            "ProductCompany": null,
            "CompanyID": null,
            "DinMaster": null,
            "UpcMaster": null,
            "DrgIdMaster": null,
            "DrgPackIdMaster": null,
            "QuickCodeMaster": null,
            "BarCode": null,
            "QRCode": null,
            "Vendor_ID": null,
            "CreatedBy": null,
            "ID": data.id,
            "EditBy": user?.userId,
            "ISUPDATE": 0,
        }
        let sentObj = {
            "sid": null,
            "lid": null,
            "reqInfo": null,
            "msgType": "UPDATEPRODUCT",
            "data": obj,
            "debug": null,
            "token": null
        }
        dispatch(updateProduct(sentObj)).then(res => {
            setIsLoading(false)
            dispatch(deleteRecord(obj))
            setOpen(false)
        })
    }

    return (
        <>
            <Box sx={{ margin: '15px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant='h1' >Products</Typography>
                <ButtonContainer>
                    <PermissionComp list={['Products', 'packSize']}>
                        <ModalButton open={openPackSize} setOpen={setOpenPackSize} tooltip={'Create PackSize'} type={'Button'}>
                            <PackSize setOpen={setOpenPackSize} />
                        </ModalButton>
                    </PermissionComp>
                    <PermissionComp list={['Products', 'create']}>
                        <ModalButton open={open} setOpen={setOpen} tooltip={'Create Product'} type={'Button'}>
                            <ProductForm setOpen={setOpen} />
                        </ModalButton>
                    </PermissionComp>
                </ButtonContainer>
            </Box>
            <Card style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                <Grid container >
                    <Grid item xs={4} style={{ display: 'flex', alignItems: 'center' }}>
                        <SearchBar placeholder={'Search...'} handleChange={setSearch} />
                    </Grid>
                </Grid>
                <TableContainer tableHeader={tableHeader}
                    data={Products}
                    verifyParam={verifyParam}
                    isLoading={isLoading}
                    fetchList={search ? handleSearch : fetchList}
                    count={totalcount}
                    action={(data) => <TableAction id={data.id} deleteRecord={deleteProduct} />} />
            </Card>
        </>
    );
};

export default Product;




const TableAction = ({ id, deleteRecord }) => {
    const [open, setOpen] = React.useState(false);
    const [openEdit, setOpenEdit] = React.useState(false);
    return (
        <Box>
            <PermissionComp list={['Products', 'detail']}>
                <ModalButton open={open} setOpen={setOpen} tooltip={'Details'} img={details} type={'icon'}>
                    <ProductDetail setOpen={setOpen} data={id} />
                </ModalButton>
            </PermissionComp>
            <PermissionComp list={['Products', 'edit']}>
                <ModalButton open={openEdit} setOpen={setOpenEdit} tooltip={'Edit User'} img={edit} type={'icon'}>
                    <ProductForm data={id} setOpen={setOpenEdit} />
                </ModalButton>
            </PermissionComp>
            <PermissionComp list={['Wearhouse', 'delete']}>
                <IsDeleteModal onPress={() => deleteRecord(id)} />
            </PermissionComp>
        </Box>
    )
}