import { createSlice } from "@reduxjs/toolkit";
import { getAllCompany } from "../actions/company";

const initialState = {
  company: [],
  totalcount: 0,
}

const companySlice = createSlice({
  name: "company",
  initialState,

  extraReducers: (builder) => {
    builder.addCase(getAllCompany.pending, (state, action) => {
      state.company = []
    });
    builder.addCase(getAllCompany.fulfilled, (state, action) => {
      state.company = action.payload.data
      // state.totalcount = action.payload.total
    });
  },
  reducers: {
    createRecord: (state, action) => {
      state.company.unshift(action.payload)
    },
    updateRecord: (state, action) => {
      const getIndex = state.company.findIndex(item => item.id == action.payload.id)
      state.company[getIndex] = action.payload;
    },
  },
});
export default companySlice.reducer;


export const { createRecord, updateRecord } = companySlice.actions;
