import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Box, Button, Grid, Typography } from "@mui/material";
import Card from "../../components/card/Card";
import ButtonContainer from "../../components/buttonContainer";
import InputFields from "../../components/InputFields";
import './product.scss'
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { createPackSize, createProductDetail, updateProductDetail } from "../../store/actions/product";
import { setDataObject } from "../../utils/Utils";
import DatePickerFields from "../../components/datePickerField";
import SelectFields from "../../components/SelectFields";
import SaveButton from "../../components/saveButton/saveButton";
import { updateProductDetailRecord } from "../../store/reducers/product-slice";
import { createPackSizeRecord } from "../../store/reducers/filler-slice";

const PackSizeForm = (props) => {
    const { setOpen } = props
    const dispatch = useDispatch();

    const { user } = useSelector(state => state.auth)
    const [isLoading, setIsLoading] = useState(false)

    const defaultValues = useForm({
        defaultValues: {
            PackSizeName: "",

        },
    });

    const {
        control,
        handleSubmit,
        setError,
        clearErrors,
        watch,
        reset,
        resetField,
        getValues,
        formState: { errors },
    } = defaultValues;

    const submit = () => {
        setIsLoading(true)
        let saveObj = {
            "sid": null,
            "lid": null,
            "reqInfo": null,
            "msgType": "INSERTPACKSIZE",
            "data": setDataObject({ ...getValues(), "CreatedBy": user?.userId }),
            "debug": null,
            "token": null
        }

        dispatch(createPackSize(saveObj)).then(res => {
            const obj = {
                id: res.payload.id,
                name: res.payload.packSizeName,
            }
            dispatch(createPackSizeRecord(obj))
            setIsLoading(false)
            setOpen(false)
        })
    }


    return <Card style={{ flex: 1, }}>
        <Box className="productFormContainer">
            <form>
                <Grid container spacing={1}>
                    <Grid item xs={12} >
                        <Typography variant='h3' >PackSize Information</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <InputFields
                            fieldName="PackSizeName"
                            type="text"
                            label="PackSize Name"
                            control={control}
                            rules={{
                                required: "PackSize Name is required",
                            }}
                            error={errors?.PackSizeName}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <ButtonContainer isSingle>
                            <SaveButton isLoading={isLoading} onClick={handleSubmit(submit)} title={'Save'} />
                        </ButtonContainer>
                    </Grid>
                </Grid>
            </form>
        </Box>
    </Card>
};

export default PackSizeForm;
