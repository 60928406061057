import { createSlice } from "@reduxjs/toolkit";
import { getAllCompanyFillers, getAllMedicationTypeFillers, getAllPackSizeFillers, getAllPharmacyFillers, getAllProductFillers, getAllVendorFillers, getAllWearHouseFillers } from "../actions/fillers";

const initialState = {
  companyList: [],
  productList: [],
  wearhouseList: [],
  vendorList: [],
  pharmacyList: [],
  packSizeList: [],
  medicationTypeList: [],
}

const fillerSlice = createSlice({
  name: "filler",
  initialState,

  extraReducers: (builder) => {
    builder.addCase(getAllCompanyFillers.pending, (state, action) => {
      state.companyList = []
    });
    builder.addCase(getAllCompanyFillers.fulfilled, (state, action) => {
      state.companyList = action.payload.data
    });
    builder.addCase(getAllProductFillers.pending, (state, action) => {
      state.productList = []
    });
    builder.addCase(getAllProductFillers.fulfilled, (state, action) => {
      state.productList = action.payload.data
    });
    builder.addCase(getAllWearHouseFillers.pending, (state, action) => {
      state.wearhouseList = []
    });
    builder.addCase(getAllWearHouseFillers.fulfilled, (state, action) => {
      state.wearhouseList = action.payload.data
    });
    builder.addCase(getAllVendorFillers.pending, (state, action) => {
      state.vendorList = []
    });
    builder.addCase(getAllVendorFillers.fulfilled, (state, action) => {
      state.vendorList = action.payload.data
    });
    builder.addCase(getAllPharmacyFillers.pending, (state, action) => {
      state.pharmacyList = []
    });
    builder.addCase(getAllPharmacyFillers.fulfilled, (state, action) => {
      state.pharmacyList = action.payload.data
    });
    builder.addCase(getAllPackSizeFillers.pending, (state, action) => {
      state.packSizeList = []
    });
    builder.addCase(getAllPackSizeFillers.fulfilled, (state, action) => {
      state.packSizeList = action.payload.data
    });
    builder.addCase(getAllMedicationTypeFillers.pending, (state, action) => {
      state.medicationTypeList = []
    });
    builder.addCase(getAllMedicationTypeFillers.fulfilled, (state, action) => {
      state.medicationTypeList = action.payload.data
    });
  },
  reducers: {
    createPackSizeRecord: (state, action) => {
      state.medicationTypeList.unshift(action.payload)
    },
  },
});
export default fillerSlice.reducer;
export const { createPackSizeRecord } = fillerSlice.actions;
