export const permission = [
    {
        role: 1, //superadmin
        Company: {
            isEnable: true,
            create: {
                isEnable: true,
            },
            edit: {
                isEnable: true,
            }
        },
        Wearhouse: {
            isEnable: true,
            create: {
                isEnable: true,
            },
            edit: {
                isEnable: true,
            },
            delete: {
                isEnable: true,
            }
        },
        Vendor: {
            isEnable: false,
            create: {
                isEnable: false,
            },
            edit: {
                isEnable: true,
            },
            delete: {
                isEnable: true,
            }
        },
        Pharmacy: {
            isEnable: true,
            create: {
                isEnable: true,
            },
            edit: {
                isEnable: true,
            },
            delete: {
                isEnable: true,
            }
        },
        Products: {
            isEnable: true,
            create: {
                isEnable: true,
            },
            edit: {
                isEnable: true,
            },
            delete: {
                isEnable: true,
            },
            detail: {
                isEnable: true,
                create: {
                    isEnable: true,
                },
                edit: {
                    isEnable: true,
                },
            },
            packSize: {
                isEnable: true,
                create: {
                    isEnable: true,
                }
            },
        },
        Users: {
            isEnable: true,
            create: {
                isEnable: true,
            },
            edit: {
                isEnable: true,
            },
            delete: {
                isEnable: true,
            },
        },
        PO: {
            isEnable: false,
            create: {
                isEnable: false,
            },
            detail: {
                isEnable: false,
                create: {
                    isEnable: false,
                }
            },
            grno: {
                isEnable: false,
                create: {
                    isEnable: false,
                }
            }
        },
        RFM: {
            isEnable: false,
            create: {
                isEnable: false,
            }
        },
        Requests: {
            isEnable: false,
        },
    },
    {
        role: 2, //admin
        Company: {
            isEnable: false,
            create: {
                isEnable: false,
            },
            edit: {
                isEnable: false,
            }
        },
        Wearhouse: {
            isEnable: true,
            create: {
                isEnable: true,
            },
            edit: {
                isEnable: true,
            },
            delete: {
                isEnable: true,
            }
        },
        Vendor: {
            isEnable: true,
            create: {
                isEnable: true,
            },
            edit: {
                isEnable: true,
            },
            delete: {
                isEnable: true,
            }
        },
        Pharmacy: {
            isEnable: true,
            create: {
                isEnable: true,
            },
            edit: {
                isEnable: true,
            },
            delete: {
                isEnable: true,
            }
        },
        Products: {
            isEnable: true,
            create: {
                isEnable: true,
            },
            edit: {
                isEnable: true,
            },
            delete: {
                isEnable: true,
            },
            detail: {
                isEnable: true,
                create: {
                    isEnable: true,
                },
                edit: {
                    isEnable: true,
                },
            },
            packSize: {
                isEnable: true,
                create: {
                    isEnable: true,
                }
            },
        },
        Users: {
            isEnable: true,
            create: {
                isEnable: true,
            },
            edit: {
                isEnable: true,
            },
            delete: {
                isEnable: true,
            },
        },
        PO: {
            isEnable: true,
            create: {
                isEnable: true,
            },
            detail: {
                isEnable: true,
                create: {
                    isEnable: true,
                }
            },
            grno: {
                isEnable: true,
                create: {
                    isEnable: true,
                }
            }
        },
        RFM: {
            isEnable: false,
            create: {
                isEnable: false,
            }
        },
        Requests: {
            isEnable: false,
        },
    },
    {
        role: 3, //WearhouseWorker
        Company: {
            isEnable: false,
            create: {
                isEnable: false,
            },
            edit: {
                isEnable: false,
            }
        },
        Wearhouse: {
            isEnable: true,
            create: {
                isEnable: false,
            },
            edit: {
                isEnable: false,
            },
            delete: {
                isEnable: false,
            }
        },
        Vendor: {
            isEnable: true,
            create: {
                isEnable: false,
            },
            edit: {
                isEnable: false,
            },
            delete: {
                isEnable: false,
            }
        },
        Pharmacy: {
            isEnable: true,
            create: {
                isEnable: false,
            },
            edit: {
                isEnable: false,
            },
            delete: {
                isEnable: false,
            }
        },
        Products: {
            isEnable: true,
            create: {
                isEnable: false,
            },
            edit: {
                isEnable: false,
            },
            delete: {
                isEnable: false,
            },
            detail: {
                isEnable: true,
                create: {
                    isEnable: false,
                },
                edit: {
                    isEnable: false,
                },
            },
            packSize: {
                isEnable: false,
                create: {
                    isEnable: false,
                }
            },
        },
        Users: {
            isEnable: true,
            create: {
                isEnable: false,
            },
            edit: {
                isEnable: false,
            },
            delete: {
                isEnable: false,
            },
        },
        PO: {
            isEnable: false,
            create: {
                isEnable: false,
            },
            detail: {
                isEnable: false,
                create: {
                    isEnable: false,
                }
            },
            grno: {
                isEnable: false,
                create: {
                    isEnable: false,
                }
            }
        },
        RFM: {
            isEnable: false,
            create: {
                isEnable: false,
            }
        },
        Requests: {
            isEnable: true,
        },
    },
    {
        role: 4, //pharmacist
        Company: {
            isEnable: false,
            create: {
                isEnable: false,
            },
            edit: {
                isEnable: false,
            }
        },
        Wearhouse: {
            isEnable: true,
            create: {
                isEnable: false,
            },
            edit: {
                isEnable: false,
            },
            delete: {
                isEnable: false,
            }
        },
        Vendor: {
            isEnable: false,
            create: {
                isEnable: false,
            },
            edit: {
                isEnable: false,
            },
            delete: {
                isEnable: false,
            }
        },
        Pharmacy: {
            isEnable: false,
            create: {
                isEnable: false,
            },
            edit: {
                isEnable: false,
            },
            delete: {
                isEnable: false,
            }
        },
        Products: {
            isEnable: true,
            create: {
                isEnable: false,
            },
            edit: {
                isEnable: false,
            },
            delete: {
                isEnable: false,
            },
            detail: {
                isEnable: true,
                create: {
                    isEnable: false,
                },
                edit: {
                    isEnable: false,
                },
            },
            packSize: {
                isEnable: false,
                create: {
                    isEnable: false,
                }
            },
        },
        Users: {
            isEnable: false,
            create: {
                isEnable: false,
            },
            edit: {
                isEnable: false,
            },
            delete: {
                isEnable: false,
            },
        },
        PO: {
            isEnable: false,
            create: {
                isEnable: false,
            },
            detail: {
                isEnable: false,
                create: {
                    isEnable: false,
                }
            },
            grno: {
                isEnable: false,
                create: {
                    isEnable: false,
                }
            }
        },
        RFM: {
            isEnable: true,
            create: {
                isEnable: true,
            }
        },
        Requests: {
            isEnable: false,
        },
    }
]