import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../interfaces/api.interface";

import { uiActions } from "../reducers/ui-slice";
import httpService from "../../services/http.service";

export const getAllWarehouse = createAsyncThunk(
  "getAllWarehouse",
  async (data, thunkAPI) => {
    try {
      const response = await httpService.call(api.generalCall(), data, thunkAPI);
      if (response.status == 200 && !response.data.data.hasOwnProperty('Error')) {
        return response.data
      } else {
        thunkAPI.dispatch(
          uiActions.showNotification({
            status: "error",
            title: "Failed!",
            message: response.data.data.Error,
          })
        );
        return thunkAPI.rejectWithValue();
      }
    } catch (error) {
      thunkAPI.dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Authorization Failed!",
          message: "Invalid Username/Password",
        })
      );
      return thunkAPI.rejectWithValue();
    }
  }
);
export const getAllWarehouseProductPaging = createAsyncThunk(
  "getAllWarehouseProductPaging",
  async (data, thunkAPI) => {
    try {
      const response = await httpService.call(api.generalCall(), data, thunkAPI);
      if (response.status == 200 && !response.data.data.hasOwnProperty('Error')) {
        return {
          list: response.data.data,
          total: response.data.data[0].recordCount
        };
      } else {
        thunkAPI.dispatch(
          uiActions.showNotification({
            status: "error",
            title: "Failed!",
            message: response.data.data.Error,
          })
        );
        return thunkAPI.rejectWithValue();
      }
    } catch (error) {
      thunkAPI.dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Authorization Failed!",
          message: "Invalid Username/Password",
        })
      );
      return thunkAPI.rejectWithValue();
    }
  }
);

export const exportAllWarehouseProductByVendor = createAsyncThunk(
  "exportAllWarehouseProductByVendor",
  async (data, thunkAPI) => {
    try {
      const response = await httpService.call(api.generalCall(), data, thunkAPI);
      if (response.status == 200 && !response.data.data.hasOwnProperty('Error')) {
        return {
          list: response.data.data,
          total: response.data.data[0].recordCount
        };
      } else {
        thunkAPI.dispatch(
          uiActions.showNotification({
            status: "error",
            title: "Failed!",
            message: response.data.data.Error,
          })
        );
        return thunkAPI.rejectWithValue();
      }
    } catch (error) {
      thunkAPI.dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Authorization Failed!",
          message: "Invalid Username/Password",
        })
      );
      return thunkAPI.rejectWithValue();
    }
  }
);


export const createWarehouse = createAsyncThunk(
  "createWarehouse",
  async (data, thunkAPI) => {
    try {
      const response = await httpService.call(api.generalCall(), data, thunkAPI);
      if (response.status == 200 && !response.data.data.hasOwnProperty('Error')) {
        thunkAPI.dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Authorized!",
            message: "Record has added successfully",
          })
        );
        return response.data.data.Data[0]
      } else {
        thunkAPI.dispatch(
          uiActions.showNotification({
            status: "error",
            title: "Failed!",
            message: response.data.data.Error,
          })
        );
        return thunkAPI.rejectWithValue();
      }
    } catch (error) {
      thunkAPI.dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Authorization Failed!",
          message: "Invalid Username/Password",
        })
      );
      return thunkAPI.rejectWithValue();
    }
  }
);
export const updateWarehouse = createAsyncThunk(
  "updateWarehouse",
  async (data, thunkAPI) => {
    try {
      const response = await httpService.call(api.generalCall(), data, thunkAPI);
      if (response.status == 200 && !response.data.data.hasOwnProperty('Error')) {
        thunkAPI.dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Authorized!",
            message: "Record has updated successfully",
          })
        );
        return response.data.data.Data[0]
      } else {
        thunkAPI.dispatch(
          uiActions.showNotification({
            status: "error",
            title: "Failed!",
            message: response.data.data.Error,
          })
        );
        return thunkAPI.rejectWithValue();
      }
    } catch (error) {
      thunkAPI.dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Authorization Failed!",
          message: "Invalid Username/Password",
        })
      );
      return thunkAPI.rejectWithValue();
    }
  }
);