import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import InputBase from '@mui/material/InputBase';
import { IoIosSearch } from "react-icons/io";
import { TextField } from '@mui/material';
import Input from '@mui/material/Input';
import './searchBar.scss'
import { useTheme } from '@emotion/react';

export default function SearchBar(props) {
    const theme = useTheme();
    const { placeholder, handleChange, disabled } = props

    const typingTimeoutRef = React.useRef(null);

    // Function to handle change and detect stop writing
    const handleUpdate = (e) => {

        // Clear the previous timeout if user is still typing
        if (typingTimeoutRef.current) {
            clearTimeout(typingTimeoutRef.current);
        }

        // Set a timeout to call onStopWriting after 500ms (or desired delay)
        typingTimeoutRef.current = setTimeout(() => {
            onStopWriting(e);
        }, 500);
    };

    // This function gets called when the user stops typing
    const onStopWriting = (finalValue) => {

        handleChange(finalValue);
        // Perform any other logic you want here
    };
    return (
        <Box className="searchBarContainer">
            <Input style={{ width: '70%', margin: '0px 10px' }} placeholder={placeholder} disableUnderline onChange={(e) => handleUpdate(e.target.value)} disabled={disabled} />
            <Box className='iconContainer' style={{ background: theme.palette.secondary.main }}>
                <IoIosSearch color='#fff' size={18} />
            </Box>
        </Box>
    );
}