import { Box, Divider, Grid, IconButton, Typography } from "@mui/material";
import Expandable from "./expandable/expandable";
import Delete from '../assets/delete.png'
import { useTheme } from "@emotion/react";

const SelectedProduct = (props) => {
    const { title, selectedHeader, selectedParam, list, onRemove } = props
    const theme = useTheme()
    return (
        <Expandable title={title}>
            <Grid container spacing={1} sx={{ padding: '10px 20px' }}>
                {selectedHeader.map(e => <Grid xs={11 / selectedHeader.length}>
                    <Typography>{e}</Typography>
                </Grid>)}
                <Grid xs={1}>
                </Grid>
            </Grid>
            <Divider sx={{ margin: '10px 0px' }} />
            {
                list.map((e, i) => {
                    return (
                        <Grid
                            container
                            spacing={1}
                            sx={{
                                background: i % 2 ? theme.palette.rowBackground : 'transparent',
                                padding: '0px 20px'
                            }}
                            key={i}
                        >
                            {selectedParam.map(p => <Grid xs={11 / selectedHeader.length} sx={{ margin: '10px 0px' }}>
                                <Typography>{e[p]}</Typography>
                            </Grid>)}
                            <Grid xs={1} sx={{ margin: '10px 0px' }}>
                                <IconButton className='icon' size="small" onClick={() => onRemove(e, i)}>
                                    <img src={Delete} style={{ width: '25px', height: '25px', objectFit: 'contain' }} />
                                </IconButton>
                            </Grid>
                        </Grid>
                    )
                })
            }
        </Expandable>
    );

};

export default SelectedProduct;
