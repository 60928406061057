import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Box, Button, CircularProgress, Divider, Grid, Typography } from "@mui/material";
import Card from "../../components/card/Card";
import ButtonContainer from "../../components/buttonContainer";
import InputFields from "../../components/InputFields";
import SelectFields from "../../components/SelectFields";
import pharmacy from '../../assets/pharmacy.png'
import './vendor.scss'
import ActionButton from "../../components/actionButton";
import { ProfilePhoto } from "../../components/profilePhoto/profilePhoto";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { createPharmacy } from "../../store/actions/pharmacy";
import { setDataObject } from "../../utils/Utils";
import { getCities, getStates } from "../../store/actions/address";
import { createVendor, updateVendor } from "../../store/actions/vendor";
import { createRecord, updateRecord } from "../../store/reducers/vendor-slice";
import SaveButton from "../../components/saveButton/saveButton";
const VendorForm = (props) => {
    const { data, setOpen } = props
    const dispatch = useDispatch();
    const { company } = useSelector(state => state.company)
    const { user } = useSelector(state => state.auth)

    const [state, setState] = useState([])
    const [city, setCity] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    const { countries } = useSelector(state => state.address)

    const defaultValues = useForm({
        defaultValues: {
            VendorName: "",
            EmailAddress: "",
            Website: '',
            Phone: "",
            Abbrevation: "",
            City_ID: "",
            CreatedBy: "",
            Address: "",
            Company_ID: "",
            StateID: "",
            CountryID: ""
        },
    });

    const {
        control,
        handleSubmit,
        setError,
        clearErrors,
        watch,
        reset,
        resetField,
        getValues,
        formState: { errors },
    } = defaultValues;



    useEffect(() => {
        if (watch('CountryID') != "") {
            resetField("StateID", { defaultValue: '' });
            resetField("City_ID", { defaultValue: '' });
            dispatch(getStates({ country_code: watch('CountryID') })).then((res) => {
                setState(res.payload)
                const newStateId = res.payload.find(val => val.id === data?.stateID)?.iso2;
                if (newStateId) {
                    resetField("StateID", { defaultValue: newStateId });
                }
            })
        }
    }, [watch('CountryID'), data?.stateID])

    useEffect(() => {
        if (watch('StateID') != "") {
            resetField("City_ID", { defaultValue: '' });
            dispatch(getCities({ country_code: watch('CountryID'), state_code: watch('StateID') })).then((res) => {
                setCity(res.payload)
                if (data?.cityID) {
                    resetField("City_ID", { defaultValue: data?.cityID });
                }
            })
        }
    }, [watch('StateID')])

    useEffect(() => {
        const country = countries.find(val => val?.id == data?.countryID)?.iso2
        if (data?.id) {
            reset({
                VendorName: data.vendorName || "",
                EmailAddress: data.emailAddress || "",
                Website: data.website || "",
                Phone: data.phone || "",
                Abbrevation: data.abbrevation || "",
                CreatedBy: data.createdBy || "",
                Address: data.address || "",
                Company_ID: data.company_ID || "",
                CountryID: country || ""
            });
        }
    }, []);


    const submit = () => {
        const country = watch('CountryID') && countries.find(val => val?.iso2 == watch('CountryID'))?.id
        const stateId = watch('StateID') && state.find(val => val.iso2 == watch('StateID'))?.id
        setIsLoading(true)
        let saveObj = {
            "sid": null,
            "lid": null,
            "reqInfo": null,
            "msgType": "INSERTVENDOR",
            "data": setDataObject({ ...getValues(), 'CountryID': country, 'StateID': stateId, "Company_ID": user?.businessId, "CreatedBy": user?.userId }),
            "debug": null,
            "token": null
        }
        if (data?.id) {
            let updateObj = { ...saveObj }
            updateObj['data'] = { ...saveObj['data'], 'ID': data.id, 'EditBy': user?.userId, ISUPDATE: 1 }
            updateObj['msgType'] = 'UPDATEVENDOR'
            delete updateObj['data'].CreatedBy
            const sentObj = updateObj.data
            const saveObject = {
                id: sentObj.ID,
                vendorName: sentObj.VendorName,
                emailAddress: sentObj.EmailAddress,
                website: sentObj.Website,
                phone: sentObj.Phone,
                company_ID: sentObj.Company_ID,
                createdOn: data.createdOn,
                createdBy: data.createdBy,
                editOn: data.editOn,
                editBy: data.editBy,
                companyName: data.companyName,
                abbrevation: sentObj.Abbrevation,
                countryID: sentObj.CountryID,
                stateID: sentObj.StateID,
                cityID: sentObj.City_ID,
                address: sentObj.Address
            }
            dispatch(updateVendor(updateObj)).then(res => {
                setIsLoading(false)
                dispatch(updateRecord(saveObject))
                setOpen(false)
            })
        } else {
            dispatch(createVendor(saveObj)).then(res => {
                setIsLoading(false)
                let obj = res.payload
                dispatch(createRecord(obj))
                setOpen(false)
            })
        }
    }

    return <Card style={{ flex: 1, }}>
        <Box className="vendorFormContainer">
            <form>
                <Typography variant='h3' >Profile picture upload</Typography>
                <Box className='vendorProfileContainer'>
                    <ProfilePhoto image={pharmacy} />
                    <ActionButton label={'Upload vendor Photo'} />
                </Box>
                <Grid container spacing={2}>
                    <Grid item xs={12} >
                        <Typography variant='h3' >Vendor Information</Typography>
                    </Grid>
                    <Grid item xs={4} >
                        <InputFields
                            fieldName="VendorName"
                            type="text"
                            label="Vendor Name"
                            control={control}
                            rules={{
                                required: "Vendor Name is required",
                            }}
                            error={errors?.VendorName}
                        />
                    </Grid>
                    <Grid item xs={4} >
                        <InputFields
                            fieldName="Abbrevation"
                            type="text"
                            label="Abbrevation"
                            control={control}
                            rules={{
                                required: "Abbrevation is required",
                            }}
                            error={errors?.Abbrevation}
                        />
                    </Grid>
                    <Grid item xs={4} >
                        <InputFields
                            fieldName="EmailAddress"
                            type="text"
                            label="Email Address"
                            control={control}
                        />
                    </Grid>
                    <Grid item xs={4} >
                        <InputFields
                            fieldName="Website"
                            type="text"
                            label="Website"
                            control={control}
                        />
                    </Grid>
                    {user?.userType == 1 && <Grid item xs={4} >
                        <SelectFields
                            fieldName="Company_ID"
                            label="Company"
                            control={control}
                            options={company.map(({ companyName, id }) => {
                                return { label: companyName, value: id };
                            })}
                        />
                    </Grid>
                    }
                    <Grid item xs={4} >
                        <InputFields
                            fieldName="Phone"
                            type="text"
                            label="Phone"
                            control={control}
                        />
                    </Grid>
                    <Grid item xs={12} >
                        <Typography variant='h3' >Address</Typography>
                    </Grid>
                    <Grid item xs={4} >
                        <InputFields
                            fieldName="Address"
                            type="text"
                            label="Address"
                            control={control}
                        />
                    </Grid>

                    <Grid item xs={4} >
                        <SelectFields
                            fieldName="CountryID"
                            label="Country"
                            control={control}
                            options={countries.map(({ name, iso2, }) => {
                                return { label: name, value: iso2 };
                            })}

                        />
                    </Grid>

                    <Grid item xs={4} >
                        <SelectFields
                            fieldName="StateID"
                            label="State"
                            control={control}
                            disabled={!watch('CountryID')}
                            options={state && state?.map(({ name, iso2 }) => {
                                return { label: name, value: iso2 };
                            })}

                        />
                    </Grid>

                    <Grid item xs={4} >
                        <SelectFields
                            fieldName="City_ID"
                            label="City"
                            control={control}
                            disabled={!watch('StateID')}
                            options={city && city?.map(({ name, id }) => {
                                return { label: name, value: id };
                            })}

                        />
                    </Grid>

                    <Grid item xs={12}>
                        <ButtonContainer isSingle>
                            <SaveButton isLoading={isLoading} onClick={handleSubmit(submit)} title={'Save'} />
                        </ButtonContainer>
                    </Grid>
                </Grid>
            </form>
        </Box>
    </Card>
};

export default VendorForm;
