import { Button, CircularProgress } from "@mui/material";
import React from "react";
const ActionButton = ({ label, onClick, style, isLoading }) => {

    return (
        <Button size="medium" variant="contained" sx={{ backgroundImage: 'linear-gradient(to right, #44289d , #536fcf)' }} style={style} onClick={onClick} disabled={isLoading}>
            {isLoading && (
                <CircularProgress size={20} sx={{ marginRight: 1, color: "#fff" }} />
            )}
            {label}</Button>
    );
};

export default ActionButton;
