import React, { useEffect, useState } from "react";
import { Box, Typography} from '@mui/material';
import Card from "../../components/card/Card";
import TableContainer from "../../components/table/table";
import { useDispatch, useSelector } from "react-redux";
import { getAllProductDetail } from "../../store/actions/product";
import edit from '../../assets/edit.png'
import PermissionComp, { useHasPermission } from "../../components/permissionComp/permissionComp";
import ModalButton from "../../components/modalButton/modalButton";
import ProductDetailForm from "./productDetailForm";
import { filterName } from "../../utils/Utils";

const ProductDetail = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const { data } = props
    const [ProductDetails, setProductDetails] = useState([])
    const [open, setOpen] = React.useState(false);
    const { packSizeList } = useSelector(state => state.filler)

    const tableHeader = ['Product Name', 'Strenght', 'Price', 'Pack Size', 'Expiry']
    const verifyParam = ['productName', 'mg', 'price', 'packSizeValue', 'expiryTo']
    useEffect(() => {
        setIsLoading(true)
        let obj = {
            "sid": null,
            "lid": null,
            "reqInfo": null,
            "msgType": "GETALLPRODUCTDETAIL",
            "data": { "ProductID": data.id },
            "debug": null,
            "token": null
        }
        dispatch(getAllProductDetail(obj)).then(res => {
            let arr = []
            res.payload.data.forEach((ele) => {
                const obj = { ...ele }
                obj['isActive'] = obj.isActive ? "Active" : obj.isActive ? "Non-Active" : '';
                obj['packSizeValue'] = filterName(packSizeList, obj.packSize_ID)
                arr.push(obj)
            })
            setProductDetails(arr)
            setIsLoading(false)
        })
    }, [])

    const updateProductDetail = (data) => {
        let arr = [...ProductDetails]
        const index = arr.findIndex(val=>val.productDetailID == data.productDetailID)
        arr.splice(index,1,data)
        setProductDetails(arr)
    }
    const hasPermission = useHasPermission(['Products', 'detail', 'edit'])

    return (
        <>
            <Box sx={{ margin: '15px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant='h1' >Product</Typography>
                <PermissionComp list={['Products', 'detail', 'create']}>
                    <ModalButton open={open} setOpen={setOpen} tooltip={'Create Product Detail'} type={'Button'}>
                        <ProductDetailForm state={data.id} setOpen={setOpen} />
                    </ModalButton>
                </PermissionComp>
            </Box>
            <Card style={{ flex: 1 }}>
                <TableContainer tableHeader={tableHeader}
                    data={ProductDetails}
                    verifyParam={verifyParam}
                    isLoading={isLoading}
                    action={(data) => hasPermission ? <TableAction id={data.id} updateProductDetail={updateProductDetail} /> : null}
                    hasActionPermission={hasPermission}
                />
            </Card>
        </>
    );
};

export default ProductDetail;




const TableAction = ({ id, updateProductDetail }) => {
    const [openEdit, setOpenEdit] = React.useState(false);

    return (
        <Box>
            <PermissionComp list={['Products', 'detail', 'edit']}>
                <ModalButton open={openEdit} setOpen={setOpenEdit} tooltip={'Edit User'} img={edit} type={'icon'}>
                    <ProductDetailForm data={id} setOpen={setOpenEdit} updateProductDetail={updateProductDetail} />
                </ModalButton>
            </PermissionComp>
        </Box>
    )
}