import { createSlice } from "@reduxjs/toolkit";
import { getAllWarehouse, getAllWarehouseProductPaging } from "../actions/warehouse";

const initialState = {
  warehouse: [],
  totalcount: 0,
  warehouseProductPaging: [],
  wearhouseProductPagingcount: 0,
}

const warehouseSlice = createSlice({
  name: "warehouse",
  initialState,

  extraReducers: (builder) => {
    builder.addCase(getAllWarehouse.pending, (state, action) => {
      state.warehouse = []
    });
    builder.addCase(getAllWarehouse.fulfilled, (state, action) => {
      state.warehouse = action.payload.data
      // state.totalcount = action.payload.total
    });
    builder.addCase(getAllWarehouseProductPaging.pending, (state, action) => {
      state.warehouseProductPaging = []
    });
    builder.addCase(getAllWarehouseProductPaging.fulfilled, (state, action) => {
      state.warehouseProductPaging = action.payload.list
      state.wearhouseProductPagingcount = action.payload.total
    });
  },
  reducers: {
    createRecord: (state, action) => {
      state.warehouse.unshift(action.payload)
    },
    updateRecord: (state, action) => {
      const getIndex = state.warehouse.findIndex(item => item.id == action.payload.id)
      state.warehouse[getIndex] = action.payload;
    },
    deleteRecord: (state, action) => {
      const getIndex = state.warehouse.findIndex(item => item.id == action.payload.ID)
      state.warehouse.splice(getIndex, 1)
    },
  },
});
export default warehouseSlice.reducer;

export const { createRecord, updateRecord, deleteRecord } = warehouseSlice.actions;