import { Box, CircularProgress, IconButton, Tooltip, Typography } from "@mui/material";
import React from "react";
import { downloadExcel } from "../utils/Utils";
import excelExport from '../assets/excel_export.png'
import { useDispatch } from "react-redux";
import { uiActions } from "../store/reducers/ui-slice";

const ExportButton = ({ data, name, isExportLoading }) => {
    const dispatch = useDispatch();
    const handleExport = async () => {
        // if (name) {
        downloadExcel(await data(), name)
        // } else {
        //     dispatch(
        //         uiActions.showNotification({
        //             status: "error",
        //             message: "Please select vendor",
        //         })
        //     )
        // }
    }
    return (
        <Tooltip title={'Export'}>
            <IconButton onClick={handleExport} sx={{ width: '100px', margin: '0px auto' }} disabled={isExportLoading}>
                {isExportLoading && (
                    <CircularProgress size={20} sx={{ marginRight: 1, color: "#eaa" }} />
                )}
                <img src={excelExport} style={{ width: '25px', height: '25px', objectFit: 'contain' }} />
                {/* <Typography variant='body1' sx={{ marginLeft: '10px' }}>Export</Typography> */}
            </IconButton>
        </Tooltip>
    );
};

export default ExportButton;
