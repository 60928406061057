import React, { useEffect, useState } from "react";
import { Tooltip, Box, Typography, IconButton, Button } from '@mui/material';
import Card from "../../components/card/Card";
import ActionButton from "../../components/actionButton";
import TableContainer from "../../components/table/table";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllPharmacy, updatePharmacy } from "../../store/actions/pharmacy";
import PermissionComp, { useHasPermission } from "../../components/permissionComp/permissionComp";
import ModalButton from "../../components/modalButton/modalButton";
import PharmacyForm from "./pharmacyForm";
import edit from '../../assets/edit.png'
import IsDeleteModal from "../../components/isDelete";
import { deleteRecord } from "../../store/reducers/pharmacy-slice";

const Pharmacy = () => {
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const navigation = useNavigate()
    const { pharmacy } = useSelector(state => state.pharmacy)
    const { user } = useSelector(state => state.auth)
    const [Pharmacies, setPharmacies] = useState([])
    const [open, setOpen] = React.useState(false);

    const tableHeader = ['Pharmacy Name', 'Email', 'Website', 'Phone']
    const verifyParam = ['pharmacyName', 'emailAddress', 'website', 'phone']

    useEffect(() => {
        setIsLoading(true)
        let data = {
            "sid": null,
            "lid": null,
            "reqInfo": null,
            "msgType": "GETALLPHARMACY",
            "data": { "Company_ID": user?.businessId },
            "debug": null,
            "token": null
        }
        dispatch(getAllPharmacy(data)).then(res => {
            setIsLoading(false)
        })
    }, [])

    useEffect(() => {
        let arr = []
        pharmacy.forEach((ele) => {
            const obj = { ...ele }
            obj['isActive'] = obj.isActive ? "Active" : obj.isActive ? "Non-Active" : '';
            arr.push(obj)
        })
        setPharmacies(arr)

    }, [pharmacy])

    const deletePharmacy = (data) => {
        let obj = {
            "PharmacyName": null,
            "EmailAddress": null,
            "Website": null,
            "Phone": null,
            "CompanyID": null,
            "CreatedBy": null,
            "ID": data.id,
            "EditBy": user?.userId,
            "ISUPDATE": 0,
        }
        let sentObj = {
            "sid": null,
            "lid": null,
            "reqInfo": null,
            "msgType": "UPDATEPHARMACY",
            "data": obj,
            "debug": null,
            "token": null
        }
        dispatch(updatePharmacy(sentObj)).then(res => {
            setIsLoading(false)
            dispatch(deleteRecord(obj))
            setOpen(false)
        })
    }

    const hasPermission = useHasPermission(['Pharmacy', 'edit'])
    return (
        <>
            <Box sx={{ margin: '15px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant='h1' >Pharmacy</Typography>
                <PermissionComp list={['Pharmacy', 'create']}>
                    <ModalButton open={open} setOpen={setOpen} tooltip={'Create Pharmacy'} type={'Button'}>
                        <PharmacyForm setOpen={setOpen} />
                    </ModalButton>
                </PermissionComp>
            </Box>
            <Card style={{ flex: 1, overflow: 'auto' }}>
                <TableContainer tableHeader={tableHeader}
                    data={Pharmacies}
                    verifyParam={verifyParam}
                    isLoading={isLoading}
                    action={(data) => hasPermission ? <TableAction id={data.id} deleteRecord={deletePharmacy} /> : null} />
            </Card>
        </>
    );
};

export default Pharmacy;



const TableAction = ({ id, deleteRecord }) => {
    const [openEdit, setOpenEdit] = React.useState(false);

    return (
        <>
            <PermissionComp list={['Pharmacy', 'edit']}>
                <ModalButton open={openEdit} setOpen={setOpenEdit} tooltip={'Edit Pharmacy'} img={edit} type={'icon'}>
                    <PharmacyForm data={id} setOpen={setOpenEdit} />
                </ModalButton>
            </PermissionComp>
            <PermissionComp list={['Pharmacy', 'delete']}>
                <IsDeleteModal onPress={() => deleteRecord(id)} />
            </PermissionComp>
        </>
    )
}