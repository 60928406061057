import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import Card from "../../components/card/Card";
import ButtonContainer from "../../components/buttonContainer";
import InputFields from "../../components/InputFields";
import SelectFields from "../../components/SelectFields";
import './po.scss'
import ActionButton from "../../components/actionButton";
import { ProfilePhoto } from "../../components/profilePhoto/profilePhoto";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { createPo } from "../../store/actions/po";
import { setDataObject } from "../../utils/Utils";
import DatePickerFields from "../../components/datePickerField";
import { createPORecord } from "../../store/reducers/po-slice";
import moment from "moment";
import SaveButton from "../../components/saveButton/saveButton";
import dayjs from "dayjs";

const PoForm = (props) => {
    const { setOpen } = props
    const dispatch = useDispatch();
    const navigation = useNavigate()
    const { vendorList } = useSelector(state => state.filler)
    const { user } = useSelector(state => state.auth)
    const [isLoading, setIsLoading] = useState(false);

    const defaultValues = useForm({
        defaultValues: {
            Pono: "",
            Podate: "",
            Vendor_ID: "",
            Notes: ""
        },
    });

    const {
        control,
        handleSubmit,
        setError,
        clearErrors,
        watch,
        reset,
        resetField,
        getValues,
        formState: { errors },
    } = defaultValues;


    const submit = () => {
        setIsLoading(true)
        let data = {
            "sid": null,
            "lid": null,
            "reqInfo": null,
            "msgType": "INSERTPO",
            "data": setDataObject({ ...getValues(), "CompanyID": user?.businessId, "CreatedBy": user?.userId }),
            "debug": null,
            "token": null
        }

        if (user?.userType != 1) {
            data['data']['CompanyId'] = user?.businessId
        }
        dispatch(createPo(data)).then(res => {
            setIsLoading(false)
            let obj = res.payload
            obj['company_ID'] = obj.companyId
            obj['poNo'] = obj.pono
            obj['poDate'] = moment(obj.podate).format('YYYY-MM-DD')
            delete obj.grnTb
            delete obj.poDetailTb
            delete obj.pono
            delete obj.podate
            dispatch(createPORecord(obj))
            setOpen(false)
        })
    }


    return <Card style={{ flex: 1, }}>
        <Box className="poFormContainer">
            <Typography variant='h2' >General Information</Typography>
            <Divider sx={{ margin: '10px 0px' }} />
            <form>
                {/* <Typography variant='h3' >Profile picture upload</Typography> */}
                {/* <Box className='poProfileContainer'>
                    <ProfilePhoto image={po} />
                    <ActionButton label={'Upload Po Photo'} />
                </Box> */}
                <Grid container spacing={2}>
                    <Grid item xs={12} >
                        <Typography variant='h3' >Po Information</Typography>
                    </Grid>
                    <Grid item xs={6} >
                        <InputFields
                            fieldName="Pono"
                            type="text"
                            label="Po No"
                            control={control}
                            rules={{
                                required: "Po No is required",
                            }}
                            error={errors?.Pono}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <DatePickerFields
                            fieldName="Podate"
                            type="date"
                            label="Po Date "
                            control={control}
                            rules={{
                                required: "Po Date is required",
                            }}
                            minDate={dayjs()}
                            error={errors?.Podate}
                        />
                    </Grid>
                    <Grid item xs={6} >
                        <SelectFields
                            fieldName="Vendor_ID"
                            label="Vendor"
                            control={control}
                            options={vendorList.map(({ name, id }) => {
                                return { label: name, value: id };
                            })}

                            rules={{
                                required: "Vendor is required",
                            }}
                            error={errors?.Vendor_ID}
                        />
                    </Grid>

                    {/* {user?.userType == 1 && <Grid item xs={6} >
                        <SelectFields
                            fieldName="CompanyID"
                            label="Company"
                            control={control}
                            options={company.map(({ companyName, id }) => {
                                return { label: companyName, value: id };
                            })}

                            rules={{
                                required: "Company is required",
                            }}
                            error={errors?.CompanyID}
                        />
                    </Grid>} */}

                    <Grid item xs={12}>
                        <ButtonContainer isSingle>
                            <SaveButton isLoading={isLoading} onClick={handleSubmit(submit)} title={'Save'} />
                        </ButtonContainer>
                    </Grid>
                </Grid>
            </form>
        </Box>
    </Card>
};

export default PoForm;
