import React, { useEffect, useState } from "react";
import TableContainer from "../../components/table/table";
import { useDispatch, useSelector } from "react-redux";
import { getAllRFTSDetail } from "../../store/actions/rfts";
import moment from "moment";
import { filterName } from "../../utils/Utils";
import ExportButton from "../../components/exportButton";

const RftsDetailView = (props) => {
    const { data } = props
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);

    const { packSizeList, medicationTypeList } = useSelector(state => state.filler)

    const [Products, setProducts] = useState([])
    const [isExportLoading, setIsExportLoading] = useState(false);

    const tableHeader = ['DIN Number', 'Product Name', 'Type of Medication', 'Strenght', 'Requested Quantity', 'Pack Size', 'Expiry', 'Received Quantity']
    const verifyParam = ['diN_Master', 'productName', 'dosageValue', 'mg', 'requiredQuantity', 'packSizeValue', 'expiry', 'productQtySent']

    useEffect(() => {
        getRFTSDetail()
    }, [])

    const getRFTSDetail = () => {
        setIsLoading(true)
        setProducts([])
        let obj = {
            "sid": null,
            "lid": null,
            "reqInfo": null,
            "msgType": "GETALLRFTSBYRFTSID",
            "data": { "RFTS_ID": data?.rftsid },
            "debug": null,
            "token": null
        };

        dispatch(getAllRFTSDetail(obj)).then(res => {
            setIsLoading(false)
            const result = res.payload.data.reduce((acc, current) => {
                const product = acc.find(p => p.product_ID === current.product_ID);
                if (product) {
                    product?.records?.push({ ...current, sentQty: "", expiry: moment(current.expiryTo).format('YYYY-MM-DD') });
                } else {
                    acc.push({
                        product_ID: current.product_ID,
                        productName: current.productName,
                        requiredQuantity: current.qty,
                        expiry: moment(current.expiryTo).format('YYYY-MM-DD'),
                        diN_Master: current.diN_Master,
                        productAvailableQty: current.productAvailableQty,
                        mg: current.mg,
                        packSize_Master: current.packSize_Master,
                        packSizeValue: filterName(packSizeList, current.packSize_ID),
                        dosageValue: filterName(medicationTypeList, current.dosage_ID),
                        productDetail_ID: current.productDetail_ID,
                        productQtySent: current.productQtySent,
                    });
                }
                return acc;
            }, []);
            setProducts(result)
        });
    }

    const exportInventory = () => {
        setIsExportLoading(true)
        const MainPromise = new Promise((resolve, reject) => {
            const result = Products.map(item => ({
                DIN_Number: item.diN_Master,
                Product_Name: item.productName,
                Strength: item.mg,
                Available_Quantity: item.productAvailableQty,
                Requested_Quantity: item.requiredQuantity,
                PackSize: item.packSizeValue,
                Expiry: item.expiry,
                Received_Quantity: item.productQtySent,
            }))
            setIsExportLoading(false)
            resolve(result)
        });
        return MainPromise;
    }

    return <>
        <ExportButton data={exportInventory} name={`${data.pharmacyName}_${data.requestDate}`} isExportLoading={isExportLoading} />
        <TableContainer tableHeader={tableHeader}
            data={Products}
            verifyParam={verifyParam}
            isLoading={isLoading}
        />
    </>
};

export default RftsDetailView;
