import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../interfaces/api.interface";

import { uiActions } from "../reducers/ui-slice";
import httpService from "../../services/http.service";

export const getAllProduct = createAsyncThunk(
  "getAllProduct",
  async (data, thunkAPI) => {
    try {
      const response = await httpService.call(api.generalCall(), data, thunkAPI);
      if (response.status == 200 && !response.data.data.hasOwnProperty('Error')) {
        return {
          list: response.data.data,
          total: response.data.data[0].recordCount
        };
      } else {
        thunkAPI.dispatch(
          uiActions.showNotification({
            status: "error",
            title: "Failed!",
            message: response.data.data.Error,
          })
        );
        return thunkAPI.rejectWithValue();
      }
    } catch (error) {
      thunkAPI.dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Authorization Failed!",
          message: "Invalid Username/Password",
        })
      );
      return thunkAPI.rejectWithValue();
    }
  }
);
export const getAllProductDetail = createAsyncThunk(
  "getAllProductDetail",
  async (data, thunkAPI) => {
    try {
      const response = await httpService.call(api.generalCall(), data, thunkAPI);
      console.log(response)
      if (response.status == 200 && !response.data.data.hasOwnProperty('Error')) {
        return response.data
      } else {
        thunkAPI.dispatch(
          uiActions.showNotification({
            status: "error",
            title: "Failed!",
            message: response.data.data.Error,
          })
        );
        return thunkAPI.rejectWithValue();
      }
    } catch (error) {
      thunkAPI.dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Authorization Failed!",
          message: "Invalid Username/Password",
        })
      );
      return thunkAPI.rejectWithValue();
    }
  }
);


export const createProduct = createAsyncThunk(
  "createProduct",
  async (data, thunkAPI) => {
    try {
      const response = await httpService.call(api.generalCall(), data, thunkAPI);
      console.log(response)
      if (response.status == 200 && !response.data.data.hasOwnProperty('Error')) {
        thunkAPI.dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Authorized!",
            message: "Record has added successfully",
          })
        );
        return response.data.data.Data[0]
      } else {
        thunkAPI.dispatch(
          uiActions.showNotification({
            status: "error",
            title: "Failed!",
            message: response.data.data.Error,
          })
        );
        return thunkAPI.rejectWithValue();
      }
    } catch (error) {
      thunkAPI.dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Authorization Failed!",
          message: "Invalid Username/Password",
        })
      );
      return thunkAPI.rejectWithValue();
    }
  }
);
export const updateProduct = createAsyncThunk(
  "updateProduct",
  async (data, thunkAPI) => {
    try {
      const response = await httpService.call(api.generalCall(), data, thunkAPI);
      console.log(response)
      if (response.status == 200 && !response.data.data.hasOwnProperty('Error')) {
        thunkAPI.dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Authorized!",
            message: "Record has updated successfully",
          })
        );
        return response.data.data.Data[0]
      } else {
        thunkAPI.dispatch(
          uiActions.showNotification({
            status: "error",
            title: "Failed!",
            message: response.data.data.Error,
          })
        );
        return thunkAPI.rejectWithValue();
      }
    } catch (error) {
      thunkAPI.dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Authorization Failed!",
          message: "Invalid Username/Password",
        })
      );
      return thunkAPI.rejectWithValue();
    }
  }
);

export const createPackSize = createAsyncThunk(
  "createPackSize",
  async (data, thunkAPI) => {
    try {
      const response = await httpService.call(api.generalCall(), data, thunkAPI);
      console.log(response)
      if (response.status == 200 && !response.data.data.hasOwnProperty('Error')) {
        thunkAPI.dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Authorized!",
            message: "Record has added successfully",
          })
        );
        return response.data.data.Data[0]
      } else {
        thunkAPI.dispatch(
          uiActions.showNotification({
            status: "error",
            title: "Failed!",
            message: response.data.data.Error,
          })
        );
        return thunkAPI.rejectWithValue();
      }
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);
export const createProductDetail = createAsyncThunk(
  "createProductDetail",
  async (data, thunkAPI) => {
    try {
      const response = await httpService.call(api.generalCall(), data, thunkAPI);
      console.log(response)
      if (response.status == 200 && !response.data.data.hasOwnProperty('Error')) {
        thunkAPI.dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Authorized!",
            message: "Record has added successfully",
          })
        );
        return response.data
      } else {
        thunkAPI.dispatch(
          uiActions.showNotification({
            status: "error",
            title: "Failed!",
            message: response.data.data.Error,
          })
        );
        return thunkAPI.rejectWithValue();
      }
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);
export const updateProductDetail = createAsyncThunk(
  "updateProductDetail",
  async (data, thunkAPI) => {
    try {
      const response = await httpService.call(api.generalCall(), data, thunkAPI);
      console.log(response)
      if (response.status == 200 && !response.data.data.hasOwnProperty('Error')) {
        thunkAPI.dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Authorized!",
            message: "Record has updated successfully",
          })
        );
        return response.data
      } else {
        thunkAPI.dispatch(
          uiActions.showNotification({
            status: "error",
            title: "Failed!",
            message: response.data.data.Error,
          })
        );
        return thunkAPI.rejectWithValue();
      }
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);