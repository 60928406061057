import { ImOffice } from "react-icons/im";
import { FaWarehouse } from "react-icons/fa6";
import { MdLocalPharmacy } from "react-icons/md";
import { GiMedicines } from "react-icons/gi";
import { FaUser } from "react-icons/fa";
import { FaBorderAll } from "react-icons/fa";
import { MdGrading } from "react-icons/md";
import { FaSitemap } from "react-icons/fa";

export const navData = [
  {
    label: "Company",
    icon: <ImOffice color="#fff" />,
    url: "companies",
    subLable: [
    ],
  },
  {
    label: "Wearhouse",
    icon: <FaWarehouse color="#fff" />,
    url: "wearhouses",
    subLable: [
    ],
  },
  {
    label: "Vendor",
    icon: <FaSitemap color="#fff" />,
    url: "vendor",
    subLable: [
    ],
  },
  {
    label: "Pharmacy",
    icon: <MdLocalPharmacy color="#fff" />,
    url: "pharmacies",
    subLable: [
    ],
  },
  {
    label: "Products",
    icon: <GiMedicines color="#fff" />,
    url: "products",
    subLable: [
    ],
  },
  {
    label: "Users",
    icon: <FaUser color="#fff" />,
    url: "users",
    subLable: [
    ],
  },
  {
    label: "PO",
    icon: <FaBorderAll color="#fff" />,
    url: "po",
    subLable: [
    ],
  },
  {
    label: "RFM",
    icon: <ImOffice color="#fff" />,
    url: "rfts",
    subLable: [
    ],
  },
  {
    label: "Requests",
    icon: <MdGrading color="#fff" />,
    url: "requests",
    subLable: [
    ],
  },
];
export const nav = {
  presentation: navData[0],
};
