import React, { useEffect, useState } from "react";
import { Box, Typography, Grid, Autocomplete, TextField, Button } from '@mui/material';
import Card from "../../components/card/Card";
import TableContainer from "../../components/table/table";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import details from '../../assets/details.png'
import SearchBar from "../../components/searchBar/searchBar";
import { filterName } from "../../utils/Utils";
import { exportAllWarehouseProductByVendor, getAllWarehouseProductPaging } from "../../store/actions/warehouse";
import ModalButton from "../../components/modalButton/modalButton";
import WearhouseProductDetail from "./wearhouseProductsDetail";
import ExportButton from "../../components/exportButton";
import { uiActions } from "../../store/reducers/ui-slice";
import moment from "moment";
import MultiSearch from "../../layouts/multiSearch/multiSearch";
import InputFields from "../../components/InputFields";
import { useForm } from "react-hook-form";
import SelectFields from "../../components/SelectFields";
import ButtonContainer from "../../components/buttonContainer";
import SaveButton from "../../components/saveButton/saveButton";

const WearhouseProducts = () => {
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const { warehouseProductPaging, wearhouseProductPagingcount } = useSelector(state => state.warehouse)

    const { vendorList, medicationTypeList } = useSelector(state => state.filler)
    const [Products, setProducts] = useState([])
    const [vendor, setVendor] = useState([])
    const location = useLocation();
    const { state } = location

    const [search, setSearch] = useState('')
    const [isExportLoading, setIsExportLoading] = useState(false);

    const tableHeader = ['DIN Number', 'Vendor', 'Product Name','Type of Medication', 'Strenght',  'Quantity']
    const verifyParam = ['diN_Master', 'vendorValue', 'productName','dosageValue', 'mg', 'productQty']

    const defaultValues = useForm({
        defaultValues: {
            Vendor_ID: "",
            ProductName: "",
            Strength: "",
        },
    });

    const {
        control,
        handleSubmit,
        setError,
        clearErrors,
        watch,
        reset,
        resetField,
        getValues,
        formState: { errors },
    } = defaultValues;

    useEffect(() => {
        fetchList()
    }, [search, vendor])

    const fetchList = (obj, rowsPerPage, page) => {
        setIsLoading(true)
        let data = {
            "sid": null,
            "lid": null,
            "reqInfo": null,
            "msgType": "GETALLWEARHOUSEPRODUCTPAGING",
            "data": {
                "WearHouse_ID": state.id,
                'PageIndex': page ? page + 1 : 1,
                "PageSize": rowsPerPage ? rowsPerPage : 10,
                "Filter": search ? search : null,
                "Vendor_ID": watch('Vendor_ID') ? watch('Vendor_ID') : null,
                "ProductName": watch('ProductName') ? watch('ProductName') : null,
                "Strength": watch('Strength') ? watch('Strength') : null
            },
            "debug": null,
            "token": null
        }
        console.log(data)
        dispatch(getAllWarehouseProductPaging(data)).then(res => {
            setIsLoading(false)
        })
    }


    const exportInventory = () => {
        setIsExportLoading(true)
        const MainPromise = new Promise((resolve, reject) => {
            let data = {
                "sid": null,
                "lid": null,
                "reqInfo": null,
                "msgType": "GETALLWEARHOUSEPRODUCTPAGING",
                "data": { "WearHouse_ID": state.id, "Filter": search ? search : null, "Vendor_ID": vendor ? vendor : null },
                "debug": null,
                "token": null
            }
            // if (vendor) {
            dispatch(exportAllWarehouseProductByVendor(data)).then(res => {
                const result = res?.payload?.list.flatMap(item =>
                    item.subProduct.map(sub => ({
                        DIN_Number: item.diN_Master,
                        Product_Name: item.productName,
                        Company: item.companyName,
                        Vendor: filterName(vendorList, item.vendor_ID),
                        Strength: item.mg,
                        PackSize: sub.packSize_Detail,
                        Expiry: sub.expiryTo,
                        Quantity: sub.productDetailQty
                    }))
                );
                setIsExportLoading(false)
                resolve(result)
            })
            // }
        });
        return MainPromise;
    }

    useEffect(() => {
        let arr = []
        warehouseProductPaging.forEach((ele) => {
            const obj = { ...ele }
            obj['vendorValue'] = filterName(vendorList, obj.vendor_ID);
            obj['dosageValue'] = filterName(medicationTypeList, obj.dosage_ID);
            arr.push(obj)
        })
        setProducts(arr)

    }, [warehouseProductPaging])



    return (
        <>
            <Box sx={{ margin: '15px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant='h1' >Wearhouse Products</Typography>
            </Box>
            <Card style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                <Grid container >
                    <Grid item xs={4} style={{ display: 'flex', alignItems: 'center' }}>
                        <SearchBar placeholder={'Search...'} handleChange={setSearch} disabled={isLoading} />
                        <MultiSearch>
                            <form style={{margin:'10px'}}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} >
                                        <InputFields
                                            fieldName="ProductName"
                                            type="text"
                                            label="Product Name"
                                            control={control}
                                        />
                                    </Grid>
                                    <Grid item xs={12} >
                                        <InputFields
                                            fieldName="Strength"
                                            type="text"
                                            label="Strength"
                                            control={control}
                                        />
                                    </Grid>
                                    <Grid item xs={12} >
                                        <SelectFields
                                            fieldName="Vendor_ID"
                                            label="Vendor"
                                            control={control}
                                            options={vendorList.map(({ name, id }) => {
                                                return { label: name, value: id };
                                            })}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <ButtonContainer>
                                            <Button size="medium" variant="contained" color="secondary" onClick={() => {
                                                reset()
                                                fetchList()
                                                }}>Clear</Button>
                                            <SaveButton isLoading={isLoading} onClick={fetchList} title={'Search'} />
                                        </ButtonContainer>
                                    </Grid>
                                </Grid>
                            </form>
                        </MultiSearch>
                    </Grid>
                    <Grid item xs={7}></Grid>
                    <Grid item xs={1}>
                        {/* <Autocomplete
                            disablePortal
                            options={vendorList}
                            sx={{ width: '80%' }}
                            size="small"
                            getOptionLabel={(option) => {
                                return vendorList.find(e => (e.name == option.name))?.name || ""
                            }}
                            disabled={isLoading}
                            onChange={(_, data) => setVendor(data?.id)}
                            renderInput={(params) => <TextField {...params} label="Vendor" />}
                        /> */}
                        <ExportButton data={exportInventory} name={filterName(vendorList, vendor) ? filterName(vendorList, vendor) : 'Inventory'} isExportLoading={isExportLoading} />
                    </Grid>
                </Grid>
                <TableContainer tableHeader={tableHeader}
                    data={Products}
                    verifyParam={verifyParam}
                    isLoading={isLoading}
                    count={wearhouseProductPagingcount}
                    fetchList={fetchList}
                    action={TableAction}
                />
            </Card >
        </>
    );
};

export default WearhouseProducts;


const TableAction = ({ id }) => {
    const [open, setOpen] = React.useState(false);

    return (
        <ModalButton open={open} setOpen={setOpen} tooltip={'Details'} img={details} type={'icon'}>
            <WearhouseProductDetail setOpen={setOpen} state={id} />
        </ModalButton>
    )
}