import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Box, Grid, Typography } from "@mui/material";
import Card from "../../components/card/Card";
import ButtonContainer from "../../components/buttonContainer";
import './product.scss'
import { useDispatch, useSelector } from "react-redux";
import { createProductDetail, updateProductDetail } from "../../store/actions/product";
import { setDataObject } from "../../utils/Utils";
import DatePickerFields from "../../components/datePickerField";
import SelectFields from "../../components/SelectFields";
import SaveButton from "../../components/saveButton/saveButton";
import { updateProductDetailRecord } from "../../store/reducers/product-slice";
import InputFields from "../../components/InputFields";

const ProductDetailForm = (props) => {
    const { data, state, setOpen } = props
    const dispatch = useDispatch();
    const { packSizeList } = useSelector(state => state.filler)

    const { user } = useSelector(state => state.auth)
    const [isLoading, setIsLoading] = useState(false)

    const defaultValues = useForm({
        defaultValues: {
            ExpiryTo: "",
            packSize_ID: "",
            UpcDetail: "",
            QRCode: "",
            Price: ""
        },
    });

    const {
        control,
        handleSubmit,
        setError,
        clearErrors,
        watch,
        reset,
        resetField,
        getValues,
        formState: { errors },
    } = defaultValues;

    useEffect(() => {
        console.log(data)
        if (data?.productDetailID) {
            reset({
                ExpiryTo: data.expiryTo || "",
                packSize_ID: data.packSize_ID || "",
                UpcDetail: data.upcDetail || "",
                QRCode: data.qrCode || "",
                Price: data.price || ""
            });
        }
    }, []);

    const submit = () => {
        setIsLoading(true)
        let saveObj = {
            "sid": null,
            "lid": null,
            "reqInfo": null,
            "msgType": "INSERTPRODUCTDETAIL",
            "data": setDataObject({ ...getValues(), "ProductId": state, "CreatedBy": user?.userId }),
            "debug": null,
            "token": null
        }
        if (data?.productDetailID) {
            let updateObj = { ...saveObj }
            updateObj['data'] = { ...saveObj['data'], 'ID': data.productDetailID, "ProductId": data.productID, 'EditBy': user?.userId, ISUPDATE: 1 }
            updateObj['msgType'] = 'UPDATEPRODUCTDETAIL'
            const sentObj = updateObj.data
            console.log(sentObj)
            const saveObject = {
                productID: data.productID,
                productName: data.productName,
                productGenericName: data.productGenericName,
                mg: data.mg,
                productCompany: data.productCompany,
                company_ID: data.company_ID,
                createdOn: data.createdOn,
                createdBy: data.createdBy,
                editOn: data.editOn,
                editBy: sentObj.EditBy,
                isActive: data.isActive,
                companyName: data.companyName,
                productDetailID: sentObj.ID,
                price: sentObj.Price,
                upcDetail: sentObj.UpcDetail,
                qrCode: sentObj.QRCode,
                expiryFrom: data.expiryFrom,
                expiryTo: sentObj.ExpiryTo,
                diN_Master: data.diN_Master,
                packSize_Detail: data.packSize_Detail,
                packSize_ID: data.packSize_ID,
                packSizeValue: data.packSizeValue
            }
            dispatch(updateProductDetail(updateObj)).then(res => {
                setIsLoading(false)
                updateProductDetailRecord(saveObject)
                setOpen(false)
            })
        } else {
            dispatch(createProductDetail(saveObj)).then(res => {
                setIsLoading(false)
                setOpen(false)
            })
        }
    }


    return <Card style={{ flex: 1, }}>
        <Box className="productFormContainer">
            <form>
                <Grid container spacing={1}>
                    <Grid item xs={12} >
                        <Typography variant='h3' >Product Detail Information</Typography>
                    </Grid>
                    <Grid item xs={3} >
                        <SelectFields
                            fieldName="packSize_ID"
                            label="Pack Size"
                            control={control}
                            options={packSizeList?.map(({ name, id }) => {
                                return { label: name, value: id };
                            })}
                            error={errors?.packSize_ID}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <DatePickerFields
                            fieldName="ExpiryTo"
                            type="date"
                            label="Expiry To "
                            control={control}
                            rules={{
                                required: "Expiry To is required",
                            }}
                            error={errors?.ExpiryTo}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <InputFields
                            fieldName="UpcDetail"
                            type="text"
                            label="Barcode"
                            control={control}
                            rules={{
                                required: "Barcode is required",
                            }}
                            error={errors?.UpcDetail}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <InputFields
                            fieldName="QRCode"
                            type="text"
                            label="QR Code"
                            control={control}
                            rules={{
                                required: "QR Code is required",
                            }}
                            error={errors?.QRCode}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <InputFields
                            fieldName="Price"
                            type="text"
                            label="Price"
                            control={control}
                            rules={{
                                required: "Price is required",
                            }}
                            error={errors?.Price}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <ButtonContainer isSingle>
                            <SaveButton isLoading={isLoading} onClick={handleSubmit(submit)} title={'Save'} />
                        </ButtonContainer>
                    </Grid>
                </Grid>
            </form>
        </Box>
    </Card>
};

export default ProductDetailForm;
