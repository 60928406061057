import React, { useEffect, useState } from "react";
import { Box, Divider, Grid, Typography } from "@mui/material";
import Card from "../../components/card/Card";
import { useDispatch, useSelector } from "react-redux";
import { createInventory, getAllPoDetail } from "../../store/actions/po";
import { useForm } from "react-hook-form";
import InputFields from "../../components/InputFields";
import DatePickerFields from "../../components/datePickerField";
import add from '../../assets/add.png';
import { useLocation, useNavigate } from "react-router-dom";
import { filterName, setDataObject } from "../../utils/Utils";
import TableContainer from "../../components/table/table";
import SelectedProduct from "../../components/selected";
import FilterBar from "../../components/filterBar/filterBar";
import SaveButton from "../../components/saveButton/saveButton";
import ButtonContainer from "../../components/buttonContainer";
import ModalButton from "../../components/modalButton/modalButton";

const QtyReceiveForm = (props) => {
    const dispatch = useDispatch();
    const { user } = useSelector(state => state.auth);
    const navigation = useNavigate()
    const { packSizeList } = useSelector(state => state.filler)
    const location = useLocation();
    const { state } = location
    const { podetail, item } = state;
    const [Products, setProducts] = useState([])
    const [inventoryList, setInventoryList] = useState([])
    const [selectedListDetail, setSelectedListDetail] = useState([])
    const [inventoryListDetail, setInventoryListDetail] = useState([])
    const [search, setSearch] = useState('')
    const [isLoading, setIsLoading] = useState(false);
    const [isSaveLoading, setIsSaveLoading] = useState(false);
    const tableHeader = ['DIN Number', 'Product Name', 'Strenght', 'Required Quantity', 'Remaining Quantity', "Pack Size"]
    const verifyParam = ['diN_Master', 'productName', 'mg', 'requiredQty', 'remainingQty', 'packSize']
    const selectedHeader = ['DIN Number', 'Product Name', 'Strenght', 'Pack Size', 'Expiry', 'Quantity Received']
    const selectedParam = ['diN_Master', 'productName', 'mg', 'packSizeValue', 'ExpiryTo', 'QtyRecived']

    useEffect(() => {
        getPODetail()
    }, [])

    const getPODetail = () => {
        let data = {
            "sid": null,
            "lid": null,
            "reqInfo": null,
            "msgType": "GETALLPODETAIL",
            "data": { "PO_ID": podetail?.pO_ID },
            "debug": null,
            "token": null
        };

        dispatch(getAllPoDetail(data)).then(res => {
            setIsLoading(false)
            const poProducts = res.payload.data.map(ele => (
                { ...ele, requiredQty: ele.qty, remainingQty: ele.remainingQty ? ele.remainingQty : ele.qty, ExpiryTo: '' }
            ));
            setProducts(poProducts)
            setSelectedListDetail(poProducts)
        });
    }

    const submit = () => {
        setIsSaveLoading(true)
        let data = {
            "sid": null,
            "lid": null,
            "reqInfo": null,
            "msgType": "INSERTINVENTORYREALTEDRECORD",
            "data": inventoryList,
            "debug": null,
            "token": null
        }
        dispatch(createInventory(data)).then(res => {
            setIsSaveLoading(false)
            navigation(-1)
        })
    }

    const addInventory = (obj, values) => {
        const { id, index } = obj

        let arr = [...inventoryList]
        arr.push(setDataObject({
            "Grnid": item?.id,
            "QtyRecived": values.receivedQty,
            "ExpiryTo": values.ExpiryTo,
            "Product_Id": id?.product_ID,
            "ProductName": id?.productName,
            "Company_Id": user?.businessId,
            "CreatedBy": user?.userId,
            "PackSize_ID": id.packSize_ID
        }))
        setInventoryList(arr)
        let productArr = [...Products]


        setInventoryListDetail(productArr[index])
        productArr[index]['remainingQty'] = parseInt(productArr[index]['remainingQty']) - parseInt(values.receivedQty)
        if (parseInt(productArr[index]['remainingQty']) == 0) {
            productArr.splice(index, 1)
        }
        setProducts(productArr)

    }

    const TableAction = ({ data }) => {
        const [openEdit, setOpenEdit] = React.useState(false);
        const defaultValues = useForm({
            defaultValues: {
                receivedQty: "",
                ExpiryTo: "",
            },
        });

        const {
            control,
            handleSubmit,
            setError,
            clearErrors,
            watch,
            reset,
            resetField,
            getValues,
            formState: { errors },
        } = defaultValues;

        return (
            <ModalButton open={openEdit} setOpen={setOpenEdit} tooltip={'Add Product Information'} img={add} type={'icon'}>
                <form>
                    <Grid container spacing={1}>

                        <Grid item xs={4} >
                            <DatePickerFields
                                fieldName="ExpiryTo"
                                type="date"
                                label="Expiry"
                                control={control}
                                rules={{
                                    required: "Expiry is required",
                                }}
                                error={errors?.ExpiryTo}
                            />
                        </Grid>
                        <Grid item xs={4} >
                            <InputFields
                                fieldName="receivedQty"
                                type="number"
                                label="Quantity"
                                control={control}
                                rules={{
                                    required: "Quantity is required",
                                }}
                                error={errors?.receivedQty}
                                maxlimit={Products[data.index]?.remainingQty}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <ButtonContainer isSingle>
                                <SaveButton isLoading={isLoading} onClick={handleSubmit(() => addInventory(data, getValues()))} title={'Save'} />
                            </ButtonContainer>
                        </Grid>
                    </Grid>
                </form>
            </ModalButton>
        )
    }

    const onRemove = (data, index) => {
        const getIndexOfProduct = Products.findIndex(val => val.product_ID == data.product_ID)
        if (getIndexOfProduct == -1) {
            data['remainingQty'] = data.QtyRecived
            data['ExpiryTo'] = ""
            delete data.QtyRecived
            let arr = [...Products]
            arr.unshift(data)
            setProducts(arr)
        } else {
            let arr = [...Products]
            arr[getIndexOfProduct]['remainingQty'] = parseInt(arr[getIndexOfProduct]['remainingQty']) + parseInt(data.QtyRecived)
            setProducts(arr)
        }
        inventoryList.splice(index, 1)
        setInventoryList(inventoryList)
    }

    return (
        <Card style={{ flex: 1 }}>
            <Box className="poFormHeader">
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Typography variant='h2'>Quantity Received</Typography>
                    <SaveButton isLoading={isSaveLoading} onClick={submit} title={'Received'} />
                </Box>
                <Divider sx={{ margin: '10px 0px' }} />
            </Box>
            {inventoryList.length > 0 && <SelectedProduct
                title={"Selected:"}
                selectedHeader={selectedHeader}
                selectedParam={selectedParam}
                list={inventoryList.map(e => {
                    return ({
                        ...selectedListDetail.find(val => val.product_ID == e.Product_Id),
                        QtyRecived: e?.QtyRecived,
                        ExpiryTo: e?.ExpiryTo,
                        packSizeValue: filterName(packSizeList, e.PackSize_ID)

                    })
                })
                }
                onRemove={onRemove}
            />}
            <Grid container sx={{ margin: '10px' }} >
                <Grid item xs={4} style={{ display: 'flex', alignItems: 'center' }}>
                    <FilterBar placeholder={'Search...'} handleChange={setSearch} />
                </Grid>
            </Grid>
            <Card style={{ height: '80%', display: 'flex', overflowX: 'auto' }}>
                <TableContainer tableHeader={tableHeader}
                    data={Products.filter(val => String(val?.diN_Master).includes(search) || val.productName.toLowerCase().includes(search.toLowerCase()))}
                    verifyParam={verifyParam}
                    isLoading={isLoading}
                    // extraColumnFeilds={["Expiry"]}
                    // extraColumnParams={['ExpiryTo']} //Pass the parameters in this array from which you want to get the value.
                    // extraColumn={[DateComp]}
                    action={(data) => <TableAction data={data} />}
                    actionText={"Quantity Received"} />
            </Card>
        </Card>
    );
};

export default QtyReceiveForm;


