import React, { Navigate } from "react-router-dom";
import Dashboard from "../screens/dashboard/dashboard";
import Company from "../screens/company/company";
import CompanyForm from "../screens/company/companyForm";
import WareHouse from "../screens/warehouse/warehouse";
import WareHouseForm from "../screens/warehouse/warehouseForm";
import Pharmacy from "../screens/pharmacy/pharmacy";
import PharmacyForm from "../screens/pharmacy/pharmacyForm";
import UsersForm from "../screens/users/usersForm";
import UsersProfile from "../screens/users/users";
import Product from "../screens/product/product";
import ProductForm from "../screens/product/productForm";
import Po from "../screens/po/po";
import PoForm from "../screens/po/poForm";
import GrnForm from "../screens/grn/grnForm";
import Grn from "../screens/grn/grn";
import PoDetailForm from "../screens/po/poDetailForm";
import ProductDetailForm from "../screens/product/productDetailForm";
import PoDetail from "../screens/po/poDetail";
import ProductDetail from "../screens/product/productDetail";
import QtyReceiveForm from "../screens/grn/qtyReceiveForm";
import WearhouseProducts from "../screens/wearhouseProducts/wearhouseProducts";
import WearhouseProductDetail from "../screens/wearhouseProducts/wearhouseProductsDetail";
import RFTS from "../screens/rfts/rfts";
import Vendor from "../screens/vendor/vendor";
import VendorForm from "../screens/vendor/vendorForm";
import RFTSList from "../screens/rfts/rftsList";
import RequestReceived from "../screens/requests/request";
import RequestReceiveForm from "../screens/requests/requestReceiveForm";


export const privateRoutes = [
    { index: true, element: <Navigate to={'dashboard'} /> }, 
    {
        path: "dashboard",
        element: <Dashboard />
    },
    {
        path: 'companies',
        children: [
            { index: true, element: <Company /> },
            {
                path: 'create',
                element: <CompanyForm />
            },
        ]
    },
    {
        path: 'wearhouses',
        children: [
            { index: true, element: <WareHouse /> },
            {
                path: 'create',
                element: <WareHouseForm />
            },
            {
                path: 'wearhouseProducts',
                children: [
                    { index: true, element: <WearhouseProducts /> },
                    {
                        path: 'detail',
                        element: <WearhouseProductDetail />
                    },
                ]
            },
        ]
    },
    {
        path: 'vendor',
        children: [
            { index: true, element: <Vendor /> },
            {
                path: 'create',
                element: <VendorForm />
            },
        ]
    },
    {
        path: 'pharmacies',
        children: [
            { index: true, element: <Pharmacy /> },
            {
                path: 'create',
                element: <PharmacyForm />
            },
        ]
    },
    {
        path: 'products',
        children: [
            { index: true, element: <Product /> },
            {
                path: 'create',
                element: <ProductForm />
            },
            {
                path: 'detail',
                children: [
                    { index: true, element: <ProductDetail /> },
                    {
                        path: 'create',
                        element: <ProductDetailForm />
                    },
                ]
            }
        ]
    },
    {
        path: 'users',
        children: [
            { index: true, element: <UsersProfile /> },
            {
                path: 'create',
                element: <UsersForm />
            },
        ]
    },
    {
        path: 'po',
        children: [
            { index: true, element: <Po /> },
            {
                path: 'create',
                element: <PoForm />
            },
            {
                path: 'detail',
                children: [
                    { index: true, element: <PoDetail /> },
                    {
                        path: 'create',
                        element: <PoDetailForm />
                    },
                ]
            },
            {
                path: 'grno',
                children: [
                    { index: true, element: <Grn /> },
                    {
                        path: 'create',
                        element: <GrnForm />
                    },
                    {
                        path: 'quantityReceived',
                        element: <QtyReceiveForm />
                    },
                ]
            },
        ]
    },
    {
        path: 'rfts',
        children: [
            { index: true, element: <RFTSList /> },
            {
                path: 'create',
                element: <RFTS />
            }
        ]
    },
    {
        path: 'requests',
        children: [
            { index: true, element: <RequestReceived /> },
            {
                path: 'requestReceived',
                element: <RequestReceiveForm />
            }
        ]
    }
];
