import React, { useEffect, useState } from "react";
import { Autocomplete, Box, Button, Grid, IconButton, TextField, Typography } from "@mui/material";
import Card from "../../components/card/Card";
import './po.scss'
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { createPo, getOtherVendorsList } from "../../store/actions/po";
import { filterName, setDataObject } from "../../utils/Utils";
import { getAllProduct } from "../../store/actions/product";
import add from '../../assets/add.png';
import TableContainer from "../../components/table/table";
import SelectedProduct from "../../components/selected";
import FilterBar from "../../components/filterBar/filterBar";
import SaveButton from "../../components/saveButton/saveButton";
import { useForm } from "react-hook-form";
import ModalButton from "../../components/modalButton/modalButton";
import InputFields from "../../components/InputFields";
import SelectFields from "../../components/SelectFields";
import ButtonContainer from "../../components/buttonContainer";
import POOtherVendors from "./poOtherVendors";

const PoDetailForm = (props) => {
    const dispatch = useDispatch();
    const navigation = useNavigate()
    const { user } = useSelector(state => state.auth)
    const { poID, vendorID, setOpen } = props
    const { packSizeList, medicationTypeList } = useSelector(state => state.filler)

    const [isLoading, setIsLoading] = useState(false);
    const [isSaveLoading, setIsSaveLoading] = useState(false);

    const [Products, setProducts] = useState([])
    const [ProductsList, setProductsList] = useState([])
    const [orderList, setOrderList] = useState([])
    const [orderListDetail, setOrderListDetail] = useState([])
    const [search, setSearch] = useState('')
    const [count, setCount] = useState(0)
    const tableHeader = ['DIN Number', 'Product Name', 'Type of Medication', 'Strenght']
    const verifyParam = ['diN_Master', 'productName', 'dosage', 'mg']
    const selectedHeader = ['DIN Number', 'Product Name', 'Strenght', 'Pack Size', 'Quantity Received']
    const selectedParam = ['diN_Master', 'productName', 'mg', 'packSizeValue', 'Qty']

    useEffect(() => {
        fetchList()
    }, [])

    const fetchList = (obj, rowsPerPage, page) => {
        setIsLoading(true)
        setProducts([])
        let data = {
            "sid": null,
            "lid": null,
            "reqInfo": null,
            "msgType": "GETALLPRODUCT",
            "data": { "CompanyID": user?.businessId, "Vendor_ID": vendorID, 'PageIndex': page ? page + 1 : 1, "PageSize": rowsPerPage ? rowsPerPage : 10 },
            "debug": null,
            "token": null
        }
        dispatch(getAllProduct(data)).then(res => {
            setCount(res.payload.total)
            let list = res.payload.list.map(ele => (
                { ...ele, Qty: "", PackSize_ID: "", dosage: filterName(medicationTypeList, ele?.dosage_ID) }
            ));
            setProducts(list)
            setProductsList(list)

            setIsLoading(false)
        })
    }

    const submit = () => {
        setIsSaveLoading(true)
        let data = {
            "sid": null,
            "lid": null,
            "reqInfo": null,
            "msgType": "INSERTPODETAIL",
            "data": orderList,
            "debug": null,
            "token": null
        }
        dispatch(createPo(data)).then(res => {
            setIsSaveLoading(false)
            setOpen(false)
        })
    }

    const handleChange = (value, paramName, data) => {
        const arr = [...Products]
        arr[data.index][paramName] = value
    }


    const onRemove = (data, index) => {
        const obj = {
            ...data,
            Qty: "",
            PackSize_ID: ""
        }
        let arr = [...Products]
        arr.unshift(obj)
        setProducts(arr)
        orderList.splice(index, 1)
        setOrderList(orderList)
    }

    const addInventory = (obj, index, values) => {
        let arr = [...orderList]
        arr.push(setDataObject({ "PoId": String(poID), "ProductId": obj.id, "Qty": values.Qty, "PackSize_ID": values.PackSize_ID, "CreatedBy": user?.userId }))
        setOrderList(arr)
        orderListDetail.push(obj)
        setOrderListDetail(orderListDetail)
        Products.splice(index, 1)
        setProducts(Products)
    }



    const TableAction = ({ data }) => {
        const [openEdit, setOpenEdit] = React.useState(false);
        const defaultValues = useForm({
            defaultValues: {
                Qty: "",
                PackSize_ID: "",
            },
        });

        const {
            control,
            handleSubmit,
            setError,
            clearErrors,
            watch,
            reset,
            resetField,
            getValues,
            formState: { errors },
        } = defaultValues;

        return (
            <Box sx={{ display: 'flex' }}>
                <ModalButton open={openEdit} setOpen={setOpenEdit} tooltip={'Add Product Information'} img={add} type={'icon'}>
                    <form>
                        <Grid container spacing={1}>
                            <Grid item xs={4} >
                                <InputFields
                                    fieldName="Qty"
                                    type="text"
                                    label="Quantity"
                                    control={control}
                                    rules={{
                                        required: "Quantity is required",
                                    }}
                                    error={errors?.Qty}
                                />
                            </Grid>
                            <Grid item xs={4} >
                                <SelectFields
                                    fieldName="PackSize_ID"
                                    label="Pack Size"
                                    control={control}
                                    options={packSizeList.map(({ name, id }) => {
                                        return { label: name, value: id };
                                    })}

                                    rules={{
                                        required: "Pack Size is required",
                                    }}
                                    error={errors?.PackSize_ID}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <ButtonContainer isSingle>
                                    <SaveButton isLoading={isLoading} onClick={handleSubmit(() => addInventory(data.id, data.index, getValues()))} title={'Save'} />
                                </ButtonContainer>
                            </Grid>
                        </Grid>
                    </form>
                </ModalButton>
            </Box>
        );
    };

    

    return <Card style={{ flex: 1, flexDirection: 'column' }}>
        <Box sx={{ margin: '15px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant='h1' >Purchase Order Detail</Typography>
            <ButtonContainer>
                <POOtherVendors orderListDetail={orderListDetail} orderList={orderList} vendorID={vendorID} />
                <SaveButton isLoading={isSaveLoading} onClick={submit} title={'Save'} />
            </ButtonContainer>
        </Box>
        {orderList.length > 0 && <SelectedProduct
            title={"Selected:"}
            selectedHeader={selectedHeader}
            selectedParam={selectedParam}
            list={orderList.map(e => {
                return ({
                    ...orderListDetail.find(val => val.id == e.ProductId),
                    Qty: e?.Qty,
                    PackSize_ID: e.PackSize_ID,
                    packSizeValue: filterName(packSizeList, e.PackSize_ID)
                })
            })
            }
            onRemove={onRemove}
        />}
        <Grid container >
            <Grid item xs={4} style={{ display: 'flex', alignItems: 'center' }}>
                <FilterBar placeholder={'Search...'} handleChange={setSearch} />
            </Grid>
        </Grid>
        <Card style={{ flex: 1, display: 'flex', overflowX: 'auto', flexDirection: 'column' }}>
            <TableContainer tableHeader={tableHeader}
                data={Products.filter(val => String(val?.diN_Master).includes(search) || val.productName.toLowerCase().includes(search.toLowerCase()))}
                verifyParam={verifyParam}
                isLoading={isLoading}
                count={count}
                fetchList={fetchList}
                // extraColumnFeilds={["Pack Size"]}
                // extraColumnParams={['PackSize']}
                // extraColumn={[TableExtra]}
                action={(data) => <TableAction data={data} />}
                actionText={"Quantity Request"} />
        </Card>
    </Card>
};

export default PoDetailForm;
