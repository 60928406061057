import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Box, Button, CircularProgress, Divider, Grid, Typography } from "@mui/material";
import Card from "../../components/card/Card";
import ButtonContainer from "../../components/buttonContainer";
import InputFields from "../../components/InputFields";
import SelectFields from "../../components/SelectFields";
import warehouse from '../../assets/warehouse.png'
import './warehouse.scss'
import ActionButton from "../../components/actionButton";
import { ProfilePhoto } from "../../components/profilePhoto/profilePhoto";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { createWarehouse, updateWarehouse } from "../../store/actions/warehouse";
import { setDataObject } from "../../utils/Utils";
import { createRecord, updateRecord } from "../../store/reducers/warehouse-slice";
import SaveButton from "../../components/saveButton/saveButton";
const WareHouseForm = (props) => {
    const { data, setOpen } = props
    const dispatch = useDispatch();
    const { user } = useSelector(state => state.auth)
    const { company } = useSelector(state => state.company)

    const [isLoading, setIsLoading] = useState(false)

    const defaultValues = useForm({
        defaultValues: {
            WearHouseName: "",
            EmailAddress: "",
            Website: '',
            Phone: "",
            CompanyID: "",
        },
    });

    useEffect(() => {
        if (data?.id) {
            reset({
                WearHouseName: data.wearHouseName || "",
                EmailAddress: data.emailAddress || "",
                Website: data.website || "",
                Phone: data.phone || "",
                CompanyID: data.company_ID || "",
            });
        }
    }, []);

    const {
        control,
        handleSubmit,
        setError,
        clearErrors,
        watch,
        reset,
        resetField,
        getValues,
        formState: { errors },
    } = defaultValues;

    const submit = () => {
        setIsLoading(true)
        let saveObj = {
            "sid": null,
            "lid": null,
            "reqInfo": null,
            "msgType": "INSERTWEARHOUSE",
            "data": setDataObject({ ...getValues(), "CreatedBy": user?.userId }),
            "debug": null,
            "token": null
        }
        if (user?.userType != 1) {
            saveObj['data']['CompanyID'] = user?.businessId
        }
        if (data?.id) {
            let updateObj = { ...saveObj }
            updateObj['data'] = { ...saveObj['data'], 'ID': data.id, 'EditBy': user?.userId, ISUPDATE: 1 }
            updateObj['msgType'] = 'UPDATEWEARHOUSE'
            const sentObj = updateObj.data
            const saveObject = {
                id: sentObj.ID,
                wearHouseName: sentObj.WearHouseName,
                emailAddress: sentObj.EmailAddress,
                website: sentObj.Website,
                phone: sentObj.Phone,
                company_ID: sentObj.CompanyID,
                createdOn: data.createdOn,
                createdBy: sentObj.CreatedBy,
                editOn: data.editOn,
                editBy: sentObj.EditBy,
                companyName: data.companyName
            }
            dispatch(updateWarehouse(updateObj)).then(res => {
                setIsLoading(false)
                dispatch(updateRecord(saveObject))
                setOpen(false)
            })
        } else {
            dispatch(createWarehouse(saveObj)).then(res => {
                setIsLoading(false)
                let obj = res.payload
                obj['company_ID'] = obj.companyId
                delete obj.companyId
                dispatch(createRecord(obj))
                setOpen(false)
            })
        }
    }


    return <Card style={{ flex: 1, }}>
        <Box className="wareHouseFormContainer">
            <form>
                <Typography variant='h3' >Profile picture upload</Typography>
                <Box className='wareHouseProfileContainer'>
                    <ProfilePhoto image={warehouse} />
                    <ActionButton label={'Upload Wearhouse Photo'} />
                </Box>
                <Grid container spacing={2}>
                    <Grid item xs={12} >
                        <Typography variant='h3' >Wearhouse Information</Typography>
                    </Grid>
                    <Grid item xs={6} >
                        <InputFields
                            fieldName="WearHouseName"
                            type="text"
                            label="Wearhouse Name"
                            control={control}
                            rules={{
                                required: "Wearhouse Name is required",
                            }}
                            error={errors?.WearHouseName}
                        />
                    </Grid>
                    <Grid item xs={6} >
                        <InputFields
                            fieldName="EmailAddress"
                            type="text"
                            label="Email Address"
                            control={control}
                            rules={{
                                required: "Email Address is required",
                            }}
                            error={errors?.EmailAddress}
                        />
                    </Grid>
                    <Grid item xs={6} >
                        <InputFields
                            fieldName="Website"
                            type="text"
                            label="Website"
                            control={control}
                            rules={{
                                required: "Website is required",
                            }}
                            error={errors?.Website}
                        />
                    </Grid>
                    {user?.userType == 1 && <Grid item xs={6} >
                        <SelectFields
                            fieldName="CompanyID"
                            label="Company"
                            control={control}
                            options={company.map(({ companyName, id }) => {
                                return { label: companyName, value: id };
                            })}

                            rules={{
                                required: "Company is required",
                            }}
                            error={errors?.CompanyID}
                        />
                    </Grid>
                    }

                    <Grid item xs={6} >
                        <InputFields
                            fieldName="Phone"
                            type="text"
                            label="Phone"
                            control={control}
                            rules={{
                                required: "Phone is required",
                            }}
                            error={errors?.Phone}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <ButtonContainer isSingle>
                            <SaveButton isLoading={isLoading} onClick={handleSubmit(submit)} title={'Save'} />
                        </ButtonContainer>
                    </Grid>
                </Grid>
            </form>
        </Box>
    </Card>
};

export default WareHouseForm;
