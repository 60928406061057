import React from "react";
import {
  Autocomplete,
  TextField,
  Tooltip,
} from "@mui/material";
import { Controller } from "react-hook-form";
import { styled, useTheme } from '@mui/material/styles';

const CssAutocomplete = styled(Autocomplete)(({ theme }) => ({
  '& label.Mui-focused': {
    color: theme.palette.inputLabelFocusedColor,
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: theme.palette.inputFieldsetColor,
    },
    '&:hover fieldset': {
      borderColor: theme.palette.inputFocusedColor,
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.inputFocusedColor,
    },
  },
  '& .MuiOutlinedInput-root .MuiAutocomplete-input': {
    color: theme.palette.textColor,
  },
  '& .MuiSvgIcon-root': {
    fill: theme.palette.textColor
  },
  '& .MuiSvgIcon-root': {
    fill: theme.palette.textColor
  }
}));

const SelectFields = ({
  label,
  fieldName,
  control,
  options,
  rules,
  error,
  disabled,
}) => {
  const theme = useTheme()
  return (
    <>
      <Controller
        name={fieldName}
        control={control}
        rules={rules}
        render={({ field: { onChange, value } }) => {
          return (
              <CssAutocomplete
                size="small"
                componentsProps={{
                  paper: { sx: { bgcolor: theme.palette.backgroundBaseColor } }
                }}
                disabled={disabled}
                options={options}
                onChange={(_, data) => onChange(data ? data.value : "")}
                value={value ? value : null}
                getOptionLabel={(option) => {
                  if (option.label) {
                    return option.label;
                  } else if (option) {
                    return options.filter(e => (e.value == option))[0]?.label || ""
                  }
                  return "";
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={label}
                    error={error && true}
                    autoComplete="off"
                    required={(rules?.required) && true}
                    helperText={error ? error.message : " "}
                  />
                )}
                isOptionEqualToValue={(option, value) => {
                  if (value.value) {
                    return option.value == value.value;
                  } else if (value) {
                    return option.value == value;
                  }
                }}
              />
          )
        }}
      />
    </>
  );
};

export default SelectFields;
