import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../interfaces/api.interface";

import { uiActions } from "../reducers/ui-slice";
import httpService from "../../services/http.service";

export const getAllCompanyFillers = createAsyncThunk(
  "getAllCompanyFillers",
  async (item, thunkAPI) => {
    const obj = {
      ...item,
      data: {
        ...item.data,
        "IsCompany": 1
      }
    }
    try {
      const response = await httpService.call(api.generalCall(), obj, thunkAPI);
      if (response.status == 200) {
        return response.data
      } else {
        thunkAPI.dispatch(
          uiActions.showNotification({
            status: "error",
            title: "Failed!",
            message: response.data.respdescription,
          })
        );
        return thunkAPI.rejectWithValue();
      }
    } catch (error) {
      thunkAPI.dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Authorization Failed!",
          message: "Invalid Username/Password",
        })
      );
      return thunkAPI.rejectWithValue();
    }
  }
);
export const getAllProductFillers = createAsyncThunk(
  "getAllProductFillers",
  async (item, thunkAPI) => {
    const obj = {
      ...item,
      data: {
        ...item.data,
        "IsProduct": 1
      }
    }
    try {
      const response = await httpService.call(api.generalCall(), obj, thunkAPI);
      if (response.status == 200) {
        return response.data
      } else {
        thunkAPI.dispatch(
          uiActions.showNotification({
            status: "error",
            title: "Failed!",
            message: response.data.respdescription,
          })
        );
        return thunkAPI.rejectWithValue();
      }
    } catch (error) {
      thunkAPI.dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Authorization Failed!",
          message: "Invalid Username/Password",
        })
      );
      return thunkAPI.rejectWithValue();
    }
  }
);
export const getAllWearHouseFillers = createAsyncThunk(
  "getAllWearHouseFillers",
  async (item, thunkAPI) => {
    const obj = {
      ...item,
      data: {
        ...item.data,
        "IsWearHouse": 1
      }
    }
    try {
      const response = await httpService.call(api.generalCall(), obj, thunkAPI);
      if (response.status == 200) {
        return response.data
      } else {
        thunkAPI.dispatch(
          uiActions.showNotification({
            status: "error",
            title: "Failed!",
            message: response.data.respdescription,
          })
        );
        return thunkAPI.rejectWithValue();
      }
    } catch (error) {
      thunkAPI.dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Authorization Failed!",
          message: "Invalid Username/Password",
        })
      );
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getAllVendorFillers = createAsyncThunk(
  "getAllVendorFillers",
  async (item, thunkAPI) => {
    const obj = {
      ...item,
      data: {
        ...item.data,
        "IsVendor": 1
      }
    }
    try {
      const response = await httpService.call(api.generalCall(), obj, thunkAPI);
      if (response.status == 200) {
        return response.data
      } else {
        thunkAPI.dispatch(
          uiActions.showNotification({
            status: "error",
            title: "Failed!",
            message: response.data.respdescription,
          })
        );
        return thunkAPI.rejectWithValue();
      }
    } catch (error) {
      thunkAPI.dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Authorization Failed!",
          message: "Invalid Username/Password",
        })
      );
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getAllPharmacyFillers = createAsyncThunk(
  "getAllPharmacyFillers",
  async (item, thunkAPI) => {
    const obj = {
      ...item,
      data: {
        ...item.data,
        "IsPharmacy": 1
      }
    }
    try {
      const response = await httpService.call(api.generalCall(), obj, thunkAPI);
      if (response.status == 200) {
        return response.data
      } else {
        thunkAPI.dispatch(
          uiActions.showNotification({
            status: "error",
            title: "Failed!",
            message: response.data.respdescription,
          })
        );
        return thunkAPI.rejectWithValue();
      }
    } catch (error) {
      thunkAPI.dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Authorization Failed!",
          message: "Invalid Username/Password",
        })
      );
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getAllPackSizeFillers = createAsyncThunk(
  "getAllPackSizeFillers",
  async (item, thunkAPI) => {
    const obj = {
      ...item,
      data: {
        ...item.data,
        "IsPackSize": 1
      }
    }
    try {
      const response = await httpService.call(api.generalCall(), obj, thunkAPI);
      if (response.status == 200) {
        return response.data
      } else {
        thunkAPI.dispatch(
          uiActions.showNotification({
            status: "error",
            title: "Failed!",
            message: response.data.respdescription,
          })
        );
        return thunkAPI.rejectWithValue();
      }
    } catch (error) {
      thunkAPI.dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Authorization Failed!",
          message: "Invalid Username/Password",
        })
      );
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getAllMedicationTypeFillers = createAsyncThunk(
  "getAllMedicationTypeFillers",
  async (item, thunkAPI) => {
    const obj = {
      ...item,
      data: {
        ...item.data,
        "IsMedicationType": 1
      }
    }
    try {
      const response = await httpService.call(api.generalCall(), obj, thunkAPI);
      if (response.status == 200) {
        return response.data
      } else {
        thunkAPI.dispatch(
          uiActions.showNotification({
            status: "error",
            title: "Failed!",
            message: response.data.respdescription,
          })
        );
        return thunkAPI.rejectWithValue();
      }
    } catch (error) {
      thunkAPI.dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Authorization Failed!",
          message: "Invalid Username/Password",
        })
      );
      return thunkAPI.rejectWithValue();
    }
  }
);
